import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./CustomSwiper.css";

interface CustomSwiperProps {
  children: React.ReactNode[]; // Akzeptiert beliebige JSX-Elemente als Slides
  spaceBetween?: number;
  loop?: boolean;
  autoplay?: boolean;
  autoplayDelay?: number;
  navigation?: boolean;
  pagination?: boolean;
  breakpoints?: Record<number, { slidesPerView: number }>;
}

const CustomSwiper: React.FC<CustomSwiperProps> = ({
  children,
  spaceBetween = 20,
  loop = true,
  autoplay = true,
  autoplayDelay = 1500,
  navigation = true,
  pagination = true,
  breakpoints = {
    0: { slidesPerView: 1 }, // 📱 Mobile: 1 Slide pro View
    768: { slidesPerView: 1 }, // 🖥️ Ab 768px: 2 Slides pro View
    1024: { slidesPerView: 2 }, // 🖥️ Ab 1024px: 3 Slides pro View
  },
}) => {
  return (
    <div className="custom-swiper-container">
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={spaceBetween}
        loop={loop}
        autoplay={autoplay ? { delay: autoplayDelay, disableOnInteraction: false } : false}
        navigation={navigation}
        pagination={pagination ? { clickable: true } : false}
        breakpoints={breakpoints}
      >
        {children.map((child, index) => (
          <SwiperSlide key={index} className="custom-slide">
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CustomSwiper;
