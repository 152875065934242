import React from "react";
import "./CouponCard.css";
import { BASAR_USER_IOS_APP_URL } from "../links/AppStoreLinks";
import { t } from "i18next";
import useTypedTranslation from "../../i18n/useTypedTranslation";

interface CouponCardProps {
  image: string;
  price: string;
  description: string;
  buttonText?: any;
}

const CouponCard: React.FC<CouponCardProps> = ({
  image,
  price,
  description,
  buttonText = t("download_app_redeem_coupon"),
}) => {
  const handleRedirect = () => {
    window.open(BASAR_USER_IOS_APP_URL, "_blank"); // Öffnet die Seite in einem neuen Tab
  };
  const { t } = useTypedTranslation();

  return (
    <div className="coupon-card">
      <img src={image} alt="coupon Image" className="coupon-image" />
      <div className="coupon-content">
        <h2 className="coupon-price">{price}</h2>
        <p className="coupon-description">{description}</p>
        <button className="coupon-button" onClick={handleRedirect}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default CouponCard;
