import { useState, useEffect } from "react";
import axios from "axios";
import { IReview } from "./types/IReview";

interface StoreAddress {
  street: string;
  city: string;
  zipCode: string;
  number: string;
}

interface Contact {
  tel: string;
  email: string;
}

interface OpeningTimes {
  [key: string]: {
    isOpen: boolean;
    openingTime?: string;
    closingTime?: string;
  };
}

interface Coupon {
  id: number;
  header: string;
  benefitHeader: string;
  couponImg: string;
  body: string;
}

interface Images {
    imageUrl: string
}

export interface StoreData {
  vendorId: string;
  storeName: string;
  shopImgUrl: string;
  storeInformation: {
    storeAddress: StoreAddress;
    contact: Contact;
    openingTimes: OpeningTimes;
  };
  location: {
    latitude: number;
    longitude: number;
  };
  coupons: Coupon[];
  images: Images[];
  overallReview: number;
  reviewCount: number;
  reviews: IReview[];
}

const API_BASE_URL = "https://basar.digital/api/v1/stores"; // Anpassen, falls dein Spring Boot Backend auf einem anderen Port läuft

export const useFetchStore = (storeId: string) => {
  const [storeData, setStoreData] = useState<StoreData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!storeId) return;

    const fetchStore = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/${storeId}`);
        console.log("HELO"+ response)
        setStoreData(response.data);
      } catch (err) {
        setError("Fehler beim Laden der Store-Daten" + err);
      } finally {
        setLoading(false);
      }
    };

    fetchStore();
  }, [storeId]);

  return { storeData, loading, error };
};
