// src/App.tsx
import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "../src/i18n/i18n"; // Stelle sicher, dass i18n importiert und initialisiert wird
import { Route, BrowserRouter as Router, Routes, useParams } from "react-router-dom";
import StoreProfilePage from "./pages/StoreProfilePage";
import BrowserDealsPage from "./pages/BrowserDealsPage";
import BasarAppPage from "./components/BasarAppPage";
import AboutPage from "./pages/AboutPage";
import SitemapPage from "./pages/SitemapPage";
import ResetPassword from "./pages/reset-password";
import CookiesPage from "./pages/legal/CookiesPage";
import FAQPage from "./pages/FAQPage";
import PartnerPage from "./pages/PartnerPage";
import BasarGamePage from "./pages/BasarGamePage";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import PricePage from "./pages/PricePage";
import BlogPost from "./components/blog/BlogPost";
import PartnerSearch from "./components/partner/PartnerSearch";
import PartnerDetail from "./components/partner/PartnerDetail";
import ImprintPage from "./pages/legal/ImprintPage";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import PrivacyPolicyPage from "./pages/legal/PrivacyPolicyPage";


const App: React.FC = () => {
  return (
    <div className="App">
      <Navbar />
        <Routes>
          <Route path="/" element={<BrowserDealsPage />} />
          <Route path="/basarapp" element={<BasarAppPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/basargame" element={<BasarGamePage />} />
          <Route path="/browserDeals" element={<BrowserDealsPage />} />
          <Route path="/stores/:city/:storeName" element={<StoreWrapper />} />
          <Route path="/partner" element={<PartnerPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/prices" element={<PricePage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/" element={<PartnerSearch />} />
          <Route path="/partner-detail" element={<PartnerDetail />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/imprint" element={<ImprintPage />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/cookies" element={<CookiesPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/sitemap.xml" element={<SitemapPage />} />
        </Routes>
       <Footer />
    </div>
  );
};

const StoreWrapper: React.FC = () => {
  const { storeName } = useParams<{ storeName: string }>();

  if (!storeName) return <p>Kein Store angegeben</p>;

  // 🏷️ Teile den String am letzten "-"
  const lastDashIndex = storeName.lastIndexOf("-");
  const namePart = storeName.substring(0, lastDashIndex); // 👈 Name extrahieren
  const storeId = storeName.substring(lastDashIndex + 1); // 👈 ID extrahieren

  // Prüfen, ob storeId eine Zahl ist
  if (!/^\d+$/.test(storeId)) return <p>Ungültige Store-ID</p>;

  return <StoreProfilePage storeId={storeId} />;
};

export default App;
