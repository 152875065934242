export const BASAR_PARTNER_IOS_APP_URL =
  "https://apps.apple.com/de/app/basar-partner/id6737979485";
export const BASAR_PARTNER_ANDROID_APP_URL =
  "https://play.google.com/store/apps/details?id=com.basardigital.basardigitalpartnernew&hl=de";

export const BASAR_USER_IOS_APP_URL =
  "https://apps.apple.com/de/app/basar/id6651817023";
export const BASAR_USER_ANDROID_APP_URL =
  "https://play.google.com/store/apps/details?id=com.basardigital.basardigital&hl=de";

export const BASAR_USER_APP_DYNAMIC = "tab.so/ee6f7066";
export const BASAR_PARTNER_APP_DYNAMIC = "https://hovqr.to/884435e4";
