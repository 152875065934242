import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/images/basarTextAndLogo.svg";
import { ReactComponent as PartnerLogo } from "../assets/images/basarPartnerTextAndLogo.svg";
import styles from "./Navbar.module.css";
import useTypedTranslation from "../i18n/useTypedTranslation";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { t } = useTypedTranslation();

  const toggleMenu = () => setMenuOpen((prev) => !prev);
  const handleLinkClick = () => setMenuOpen(false);

  return (
    <>
      <nav className={styles.navbar}>
        <Link to="/" className={styles.logo}>
          {location.pathname === "/partner" ? <PartnerLogo /> : <Logo />}
        </Link>

        <div className={styles.menuIcon} onClick={toggleMenu}>
          <span>{menuOpen ? "✖" : "☰"}</span>
        </div>
      </nav>

      {menuOpen && (
        <div className={styles.modalBackground} onClick={toggleMenu}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <ul className={styles.modalLinks}>
              {[{ path: "/basarapp", label: "BASAR App" },
                { path: "/browserDeals", label: t("discover") },
                { path: "/partner", label: "Partner werden" },
                { path: "/prices", label: t("price") },
                { path: "/faq", label: t("faq_title") }
              ].map(({ path, label }) => (
                <li key={path}>
                  <Link
                    to={path}
                    onClick={handleLinkClick}
                    className={location.pathname === path ? styles.active : ""}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
