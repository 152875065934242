import React from "react";
import { DeviceFrameset, DeviceOptions } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";

interface SmartphoneVideoEmbedProps {
  videoUrl: string;
  device: any;
  color?: "black" | "white" | "gold" | "rose-gold";
}

const SmartphoneVideoEmbed: React.FC<SmartphoneVideoEmbedProps> = ({
  videoUrl,
  device ,
  color,
}) => {
  return (
    <DeviceFrameset device={device} color={color}>
      <iframe
        width="100%"
        height="100%"
        src={videoUrl}
        title="BASAR Digital Game"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </DeviceFrameset>
  );
};

export default SmartphoneVideoEmbed;
