// src/services/osmService.ts
import axios from "axios";
import { debounce } from "lodash";

// Typisierung für OSM-Ergebnisse
export interface OSMAddress {
  road?: string;
  house_number?: string;
  city?: string;
  town?: string;
  village?: string;
  postcode?: string;
}

export interface OSMPlace {
  place_id: number;
  display_name: string;
  name: string;
  lat: string;
  lon: string;
  address: OSMAddress;
  osm_id: string;
}

// Hilfsfunktion, um die Stadt basierend auf den verfügbaren Feldern festzulegen
const getCityFromAddress = (address: OSMAddress): string | undefined => {
  return address.city || address.town || address.village || undefined;
};

// Funktion zum Abrufen der OSM-Ergebnisse
export const fetchOSMResults = debounce(
  async (query: string, setResults: (results: OSMPlace[]) => void) => {
    if (!query) {
      return;
    }

    try {
      const response = await axios.get<OSMPlace[]>(
        `https://nominatim.openstreetmap.org/search`,
        {
          params: {
            q: query,
            format: "json",
            addressdetails: 1,
            limit: 5,
            countrycodes: "de", // Suche auf Deutschland beschränken
            extratags: 1, // Zusätzliche Informationen wie POI-Tags abrufen
          },
          headers: {
            "User-Agent": "BASAR Business (basar@basar-digital.com)", // Ersetze durch deinen App-Namen und Kontaktinformationen
          },
        }
      );

      // Mappe die Ergebnisse, um sicherzustellen, dass immer eine Stadt vorhanden ist
      const mappedResults = response.data.map((place) => {
        const city = getCityFromAddress(place.address); // Stadt von der Adresse holen
        return {
          ...place,
          address: {
            ...place.address,
            city, // Stadt setzen, basierend auf city, town, oder village
          },
        };
      });

      setResults(mappedResults);
      console.log(JSON.stringify(mappedResults, null, 2)); // Optional: Für Debugging
    } catch (error) {
      console.error("Error fetching OSM results:", error);
    }
  },
  500
);
