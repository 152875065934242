import { useEffect, useState, useCallback, useRef } from "react";
import { fetchDealsFromApi } from "../api/fetchDealsFromApi";
import { IDeals } from "../types/IDeals";
import { ILocation } from "../types/IStoreDetails";

export const useDealData = (location: ILocation | null) => {
  const [deals, setDeals] = useState<IDeals[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  // Ref to prevent duplicate requests
  const isFetchingRef = useRef(false);

  // Handles the API response
  const handleDealsResponse = useCallback(
    (data: { content: IDeals[]; last: boolean }, reset: boolean) => {
      setDeals((prevDeals) => (reset ? data.content : [...prevDeals, ...data.content]));
      setHasMore(!data.last);
    },
    []
  );

  // Fetch function
  const fetchDeals = useCallback(
    async (location: ILocation, pageNumber: number, reset: boolean) => {
      if (isFetchingRef.current) return;
      isFetchingRef.current = true;

      try {
        setLoading(true);
        const data = await fetchDealsFromApi("de", pageNumber, 100, null, location);
        handleDealsResponse(data, reset);
      } catch (error) {
        console.error("Error fetching deals data:", error);
      } finally {
        setLoading(false);
        isFetchingRef.current = false;
      }
    },
    [handleDealsResponse]
  );

  // Reset data and fetch new deals when location changes
  useEffect(() => {
    if (!location) return;
    setPage(0); // Reset pagination on location change
    setDeals([]); // Reset deals to avoid showing old data temporarily
    fetchDeals(location, 0, true);
  }, [location, fetchDeals]);

  // Fetch more deals when the page changes
  useEffect(() => {
    if (page !== 0 && location) {
      fetchDeals(location, page, false);
    }
  }, [page, location, fetchDeals]);

  return {
    deals,
    loading,
    hasMore,
    setPage,
  };
};
