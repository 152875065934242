import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import StoreImageSlider from "../components/StoreProfileView/StoreImageSlider";
import HeaderTextComponent from "../styles/HeaderTextComponent";
import "./StoreProfilePage.css";
import NormalTextComponent from "../styles/NormalTextComponent";
import { MdLocationOn, MdPhone, MdSchedule } from "react-icons/md";
import useTypedTranslation from "../i18n/useTypedTranslation";
import { Icon } from "leaflet";
import CouponCard from "../components/StoreProfileView/CouponCard";
import CustomSwiper from "../components/StoreProfileView/CustomSwiper";
import { useFetchStore } from "../useFetchStore";
import useCurrentDay from "../hooks/useCurrentDay";
import useCurrentTime from "../hooks/useCurrentTime";
import { IOpeningTimes, OpeningTime } from "../types/IOpeningTimes";
import StoreOpenTimes from "../components/StoreProfileView/StoreOpenTimes";
import StoreReviews from "../components/StoreProfileView/StoreReviews";
import { BASAR_PARTNER_APP_DYNAMIC } from "../components/links/AppStoreLinks";

// 📍 Adresse für Google Maps & OpenStreetMap

// 🗺️ Funktion für Google Maps öffnen

const StoreProfilePage: React.FC<{ storeId: string }> = ({ storeId }) => {
  const { t } = useTypedTranslation();
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const { storeData, loading, error } = useFetchStore(storeId);
  const currentDay = useCurrentDay();
  const transformOpeningTimes = (
    openingTimes: Record<string, any>
  ): IOpeningTimes => {
    const transformed: IOpeningTimes = {};

    Object.entries(openingTimes).forEach(([day, times]) => {
      transformed[day] = {
        dayOfWeek: day, // ✅ Füge die fehlende dayOfWeek-Eigenschaft hinzu
        isOpen: times.isOpen,
        openingTime: times.openingTime ?? null,
        closingTime: times.closingTime ?? null,
      } as OpeningTime;
    });

    return transformed;
  };

  const transformedOpeningTimes = transformOpeningTimes(
    storeData?.storeInformation?.openingTimes || {}
  );
  const currentlyOpen = useCurrentTime(transformedOpeningTimes);

  const storeAddress =
    `${storeData?.storeInformation?.storeAddress?.street} ${storeData?.storeInformation?.storeAddress?.number}, ${storeData?.storeInformation?.storeAddress?.zipCode} ${storeData?.storeInformation?.storeAddress?.city}`.trim();

  // 📍 Adresse für Google Maps & OpenStreetMap
  const storeCoordinates: [number, number] = [52.4939, 13.3561]; // Beispiel-Koordinaten für die Adresse

  const openGoogleMaps = () => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      storeAddress
    )}`;
    window.open(googleMapsUrl, "_blank");
  };

  const scrollToSection = (id: string) => {
    if (id === "start") {
      // Scrollt die gesamte Seite nach oben
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      const section = document.getElementById(id);
      if (section) {
        const yOffset = -window.innerHeight * 0.15; // -10vh Offset
        const y =
          section.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  };

  const storeIcon = new Icon({
    iconUrl:
      "https://zlpnrvwbzjulimalwcvs.supabase.co/storage/v1/object/sign/avatars/MAPMARKERBASAR.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJhdmF0YXJzL01BUE1BUktFUkJBU0FSLnBuZyIsImlhdCI6MTczODkzNjE2NCwiZXhwIjoyNjAyOTM2MTY0fQ.BeFonOhpvatWIRqYAP32u-w88HtT_Xb94zfZQKiWMZ4",
    iconSize: [45, 45],
    iconAnchor: [22, 94],
    popupAnchor: [-3, -76],
  });
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(
        ".store-section, .store-section-double"
      );

      let currentSection = null;

      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        if (sectionTop < 150) {
          currentSection = section.getAttribute("id");
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!storeData) return <p>Store nicht gefunden</p>;
  // 🖼️ Store Images (Umwandlung in ein reines String-Array)

  // Konvertiere images zu einem String-Array
  // Falls storeData.images existiert, konvertiere es zu einem String-Array
  const imageUrls = [
    storeData.shopImgUrl, // Shop-Image als erstes hinzufügen
    ...storeData.images.map((img) =>
      typeof img === "string" ? img : img.imageUrl
    ),
  ].filter(Boolean); // Entfernt evtl. leere Einträge

  const getOpeningStatus = (): string => {
    if (!storeData) return "";

    const openingTimes = storeData.storeInformation.openingTimes;
    const todayTimes = openingTimes[currentDay];

    if (todayTimes?.isOpen) {
      const opening = todayTimes.openingTime || "00:00";
      const closing = todayTimes.closingTime || "00:00";

      if (currentlyOpen) {
        return `Jetzt geöffnet ${opening} - ${closing} Uhr`;
      }
      return `Öffnet heute ${opening} - ${closing} Uhr`;
    }

    // Falls heute geschlossen ist, nächsten Öffnungstag suchen
    const weekDays = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    let nextOpenDay = "";
    let nextOpenTime = "";

    for (let i = 1; i <= 7; i++) {
      const nextDayIndex = (weekDays.indexOf(currentDay) + i) % 7;
      const nextDay = weekDays[nextDayIndex];
      const nextTimes = openingTimes[nextDay];

      if (nextTimes?.isOpen) {
        nextOpenDay = t(`days.${nextDay}` as keyof typeof t);
        nextOpenTime = `${nextTimes.openingTime} - ${nextTimes.closingTime} Uhr`;
        break;
      }
    }

    return `Öffnet ${nextOpenDay} ${nextOpenTime}`;
  };

  if (!storeData) {
    return <p>Store nicht gefunden</p>;
  }

  return (
    <div className="store-profile">
      {/* 🏪 Store Name + Navbar */}
      <section className="store-profile-header" id="start">
        <button onClick={() => scrollToSection("start")} className="store-name">
          <HeaderTextComponent level={2} title={storeData.storeName}>
            {storeData.storeName}
          </HeaderTextComponent>
        </button>

        <div className="store-profile-navbar">
          <button
            onClick={() => scrollToSection("deals")}
            className={`store-profile-nav-item ${
              activeSection === "deals" ? "active" : ""
            }`}
          >
            {t("deals")}
          </button>
          <button
            onClick={() => scrollToSection("reviews")}
            className={`store-profile-nav-item ${
              activeSection === "reviews" ? "active" : ""
            }`}
          >
            {t("reviews")}
          </button>
          <button
            onClick={() => scrollToSection("infos")}
            className={`store-profile-nav-item ${
              activeSection === "infos" ? "active" : ""
            }`}
          >
            {t("info")}
          </button>
        </div>
      </section>

      <StoreImageSlider images={imageUrls} />

      <div className="info-image-section">
        {/* 🖼️ Store Images */}

        <div className="store-profile-info-row">
          {/* 📍 Adresse + Öffnungszeiten */}
          <div
            className="store-profile-info-row-item"
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  storeAddress
                )}`,
                "_blank"
              )
            }
          >
            <MdLocationOn size={"3vh"} className="store-profile-icon" />
            <NormalTextComponent className="store-profile-subinfo">
              {storeAddress}
            </NormalTextComponent>
          </div>

          <div
            className="store-profile-info-row-item"
            onClick={() => scrollToSection("infos")}
          >
            <MdSchedule size={"3vh"} className="store-profile-icon" />
            <NormalTextComponent className="store-profile-subinfo">
              {getOpeningStatus()}
            </NormalTextComponent>
          </div>
          <div className="store-profile-info-row-item">
            <MdPhone size={"3vh"} className="store-profile-icon" />
            <a
              href={`tel:${storeData.storeInformation.contact.tel}`}
              className="link-text"
            >
              <NormalTextComponent className="store-profile-subinfo">
                {storeData.storeInformation.contact.tel}
              </NormalTextComponent>
            </a>
          </div>
        </div>
      </div>

      {/* 📌 Sections für Navigation */}
      <section id="deals" className="store-section">
        <h2 className="store-section-title">{t("deals")}</h2>
        {storeData.coupons.length > 0 ? (
          <CustomSwiper spaceBetween={10} autoplay={true} navigation={false}>
            {storeData.coupons.map((coupon, index) => (
              <CouponCard
                key={index}
                image={coupon.couponImg}
                price={coupon.benefitHeader}
                description={coupon.header}
              />
            ))}
          </CustomSwiper>
        ) : (
          <div style={{ textAlign: "left" }}>
            <p>
              Leider hat {storeData.storeName} aktuell keine Coupons auf der
              BASAR App.
            </p>
            <p>
              <a href={BASAR_PARTNER_APP_DYNAMIC}>
                Du bist der Inhaber? Dann übernimm dein Geschäft über die BASAR
                Business App.
              </a>
            </p>
          </div>
        )}
      </section>

      <section id="reviews" className="store-section">
        <h2 className="store-section-title">{t("reviews")}</h2>
        <StoreReviews
          reviews={storeData.reviews}
          overallReview={storeData.overallReview}
          reviewCount={storeData.reviewCount}
        />
      </section>

      {/* 📌 Infos Section (Map & Öffnungszeiten) */}
      <section id="infos" className="store-section-double">
        <h2 className="store-section-title">{t("info")}</h2>

        <div className="info-container">
          {/* 🌍 OpenStreetMap-Karte */}
          <div className="store-map-container" onClick={openGoogleMaps}>
            <MapContainer
              center={storeCoordinates}
              zoom={16}
              className="store-map"
              scrollWheelZoom={false}
              dragging={false}
              doubleClickZoom={false}
              touchZoom={false}
              zoomControl={false}
            >
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                attribution="OpenStreetMap"
              />
              <Marker position={storeCoordinates} icon={storeIcon}>
                <Popup>{storeAddress}</Popup>
              </Marker>
            </MapContainer>
          </div>

          {/* ⏰ Öffnungszeiten */}
          <div className="open-times-container">
            <StoreOpenTimes
              openTimes={transformOpeningTimes(
                storeData.storeInformation.openingTimes
              )}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default StoreProfilePage;
