import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./StoreImageSlider.css";

interface StoreImageSliderProps {
  images: string[];
}

const StoreImageSlider: React.FC<StoreImageSliderProps> = ({ images }) => {
  // Wenn nur ein Bild vorhanden ist, zentriert anzeigen
  if (images.length === 1) {
    return (
      <div className="store-image-slider">
        
        <img src={images[0]} alt="Store Image" className="slider-image" />
      </div>
    );
  }

  return (
    <div className="store-image-slider">
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={20}
        loop={images.length > 2} // Nur loopen, wenn mehr als zwei Bilder
        autoplay={
          images.length > 2
            ? { delay: 2000, disableOnInteraction: false }
            : false
        }
        navigation={images.length > 2} // Navigation nur, wenn mehr als 2 Bilder
        breakpoints={{
          0: { slidesPerView: 1 }, // 📱 Mobile: Immer 1 Slide pro View
          768: { slidesPerView: images.length > 2 ? 2 : 1 }, // 🖥️ Desktop: Nur Slider, wenn mehr als 2 Bilder
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={`Store Image ${index + 1}`}
              className="slider-image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default StoreImageSlider;
