import { COLORS } from "./colors";

export const dS = {
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    xxl: "2rem",
    xxxl: "5rem",

  },
  fontFamily: {
    regular: "'Roboto', sans-serif",
    bold: "'Roboto Bold', sans-serif",
    italic: "'Roboto Italic', sans-serif",
  },
  spacing: {
    xs: "0.5rem",
    sm: "1rem",
    md: "1.5rem",
    lg: "2rem",
    xl: "2.5rem",
    xxl: "3rem",
  },
  // Weisen Sie COLORS hier korrekt zu
  colors: COLORS,

  borderRadius: {
    sm: "0.25rem",
    md: "0.5rem",
    lg: "1rem",
  },
  iconSize: {
    sm: "16px",
    md: "24px",
    lg: "32px",
    xl: "48px",
    xxl: "64px",
  },
  responsive: {
    width: (percent: number) => `${percent}vw`,
    height: (percent: number) => `${percent}vh`,
    fontSize: (percent: number) => `${percent}rem`,
    padding: (percent: number) => `${percent}rem`,
    margin: (percent: number) => `${percent}rem`,
  },
};

export default dS;
