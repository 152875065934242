import React from "react";
import GenericCarousel from "../components/GenericCarousel";
import HeaderTextComponent from "../styles/HeaderTextComponent";
import "./BasarGamePage.css";
import AppleBadgeBlack from "../assets/images/badges/AppleBadgeBlack";
import GoogleBadgeBlack from "../assets/images/badges/GoogleBadgeBlack";
import {
  BASAR_USER_IOS_APP_URL,
  BASAR_USER_ANDROID_APP_URL,
} from "../components/links/AppStoreLinks";
import { ReactComponent as PHONESVG } from "../assets/images/phonesvg.svg";
import gifBasarGame from "../assets/gifs/basarGame.gif";

import step1Image from "../assets/images/steps/step1.png";
import step2Image from "../assets/images/steps/step2.png";
import step3Image from "../assets/images/steps/step3.png";
import { ReactComponent as STEP1ICON } from "../assets/images/step1icon.svg";
import { ReactComponent as STEP2ICON } from "../assets/images/step2icon.svg";
import { ReactComponent as STEP3ICON } from "../assets/images/step3icon.svg";
import SmartphoneVideoEmbed from "./SmarphoneVideoEmbed";
import useTypedTranslation from "../i18n/useTypedTranslation";
import dS from "../designSystem/designSystem";
import ThreeStepComponent from "../components/ThreeStepComponent";

const BasarGamePage: React.FC = () => {
  const { t } = useTypedTranslation();

  const steps = [
    {
      icon: <STEP1ICON className="step-icon" />,
      image: step1Image,
      title: "1. Foto machen 📸",
      subtitle: "1. Foto machen 📸",
      description:
        "Finde ein lokales Geschäft und mach ein gutes Foto. Achte darauf, dass das Logo und die Adresse gut sichtbar sind.",
    },
    {
      icon: <STEP2ICON className="step-icon" />,
      image: step2Image,
      title: "2. Infos hinzufügen 📝",
      subtitle: "2. Infos hinzufügen 📝",
      description:
        "Gib wichtige Details ein: Öffnungszeiten, Standort, Besonderheiten. Je genauer, desto besser!",
    },
    {
      icon: <STEP3ICON className="step-icon" />,
      image: step3Image,
      title: "3. Coins verdienen 💰",
      subtitle: "3. Coins verdienen 💰",
      description:
        "Nach unserer Verifizierung erhältst du Coins, die du in bares Geld umwandeln kannst!",
    },
  ];

  return (
    <div className="basar-game-container">
      {/* Header Section mit Video und Erklärung */}
      <div className="gif-horizontal-basar-game">
        <img src={gifBasarGame} className="gif-game-img" />
      </div>
      <HeaderTextComponent
        level={1}
        title={"BASAR Digital Game"}
        style={{ color: dS.colors.basarColorRed, fontSize: dS.fontSize.xxxl }}
      >
        DIGITAL GAME
      </HeaderTextComponent>
      <div className="intro-section">
        <div className="video-container">
          <SmartphoneVideoEmbed
            videoUrl="https://www.youtube.com/embed/SNkhAvu43zk"
            color="black"
            device={"iPhone X"}
          />
        </div>
      </div>
      <div className="game-explanation">
        <ThreeStepComponent steps={steps} title={"So funktioniert's"} />
      </div>
      {/* Download Section */}
      <div className="download-app-section">
        <HeaderTextComponent
          level={1}
          title={"Geld verdienen im lokalen Handel!"}
          seoDescription="Geld verdienen mit Fotos"
        >
          Lade die BASAR App herunter und verdiene echte Coins!
        </HeaderTextComponent>

        <div className="badge-container-game">
          <a
            href={BASAR_USER_IOS_APP_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AppleBadgeBlack className="badge-item" />
          </a>
          <a
            href={BASAR_USER_ANDROID_APP_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GoogleBadgeBlack className="badge-item" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BasarGamePage;
