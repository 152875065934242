import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DeviceFrame from "./DeviceFrame";
import useTypedTranslation from "../../i18n/useTypedTranslation";
import "./PartnerDetail.css";
import dS from "../../designSystem/designSystem";

interface LocationState {
  name: string;
  formatted_address: string;
  place_id: string;
  photoUrl: string;
  types: string;
}

const PartnerDetail: React.FC = () => {
  const location = useLocation();
  const { name, formatted_address, photoUrl, types, place_id } =
    location.state as LocationState;

  const tallyFormUrl = `https://tally.so/r/nGq8yo?name=${name}&place_id=${place_id}&formatted_address=${formatted_address}`;
  const { t } = useTypedTranslation();

  return (
    <div className="fullscreen-overlay">
      <div className="partner-detail-container">
        <div className="text-phone-container">
          <h2 className="span-group"
          >
            <span className="span-text dark-blue">{t("your_business")}</span>
            <span className="span-text red">{t("on_the_basar_app")}</span>
            <span className="span-text black-red">
              {t("ready_to_be_discovered")}
            </span>
          </h2>

          <div className="phone-mockup-container">
            <DeviceFrame imageUrl={photoUrl} name={name} types={types} />
          </div>
        </div>

        <div className="contact-tally-form">
          <iframe
            src={tallyFormUrl}
            width="100%"
            height="100%"
            style={{ border: "none", overflow: "hidden" }} // overflow: "hidden" hier setzen
            scrolling="no" // Scrollen verhindern
            allowFullScreen
          >
            {t("loading")}
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default PartnerDetail;
