import React, { useState } from "react";
import "./StoreReviews.css";
import { IReview } from "../../types/IReview";
import { MdStar, MdStarBorder } from "react-icons/md";
import useTypedTranslation from "../../i18n/useTypedTranslation";

interface StoreReviewsProps {
  reviews: IReview[];
  overallReview: number;
  reviewCount: number;
}

const StoreReviews: React.FC<StoreReviewsProps> = ({
  reviews,
  overallReview,
  reviewCount,
}) => {
  const { t } = useTypedTranslation();
  const [showAll, setShowAll] = useState(false);

  if (!reviews || reviews.length === 0) {
    return <p className="no-reviews">{t("noReviewsAvailable")}</p>;
  }

  // Zeigt nur die ersten 3 Bewertungen an, wenn `showAll` false ist
  const displayedReviews = showAll ? reviews : reviews.slice(0, 3);

  return (
    <div className="store-reviews">
      <h2
        style={{
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {overallReview.toFixed(1)} {/* Zeigt die Sterne als Zahl */}
        <div className="review-stars">
          {Array.from({ length: 5 }, (_, i) => (
            <MdStar key={i} className="star-outline" />
          ))}
        </div>
        ({reviewCount}) {/* Anzahl der Bewertungen in Klammern */}
      </h2>

      {displayedReviews.map((review, index) => (
        <div key={index} className="review-card">
          <div className="review-header">
            <span className="review-name">{review.name}</span>
            <span className="review-date">
              {new Date(review.ratingDate).toLocaleDateString()}
            </span>
          </div>
          <div className="review-rating">
            {Array.from({ length: 5 }, (_, i) =>
              i < review.rating ? (
                <MdStar key={i} className="star-filled" />
              ) : (
                <MdStarBorder key={i} className="star-empty" />
              )
            )}
          </div>
          <p className="review-comment">{review.comment}</p>
        </div>
      ))}

      {/* Zeige den "Alle Bewertungen anzeigen"-Button nur, wenn es mehr als 3 Bewertungen gibt */}
      {reviews.length > 3 && (
        <button
          className="show-more-reviews"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? t("closeButton") : t("show_all")}
        </button>
      )}
    </div>
  );
};

export default StoreReviews;
