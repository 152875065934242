import React, { useState } from "react";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import "./DeviceFrame.css";
import useTypedTranslation from "../../i18n/useTypedTranslation";
import CouponCard from "../StoreProfileView/CouponCard";

interface DeviceFrameProps {
  imageUrl: string;
  name: string;
  types: string;
}

const DeviceFrame: React.FC<DeviceFrameProps> = ({ imageUrl, name, types }) => {
  const { t } = useTypedTranslation();
  const [activeTab, setActiveTab] = useState<"reviews" | "coupons">("reviews"); // Steuert den aktiven Tab

  // Funktion zum Rendern des Inhalts basierend auf dem aktiven Tab
  const renderContent = () => {
    if (activeTab === "reviews") {
      return (
        <div className="device-frame-text">
          <h3 className="device-frame-review-title">{t("reviews")}</h3>
          <p className="device-frame-review">4.9 ⭐⭐⭐⭐⭐</p>
          <p className="device-frame-review-text">(333 {t("reviews")})</p>
          <div className="review-card">
            <p className="review-author">Lado • 02.10.2024</p>
            <p className="review-text">{t("review_dummy_text")}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="device-frame-text coupon">
          <CouponCard
            image={require("../../assets/images/CouponImagePlaceholder.png")}
            price={"5€"}
            description={"Erhalte 5€ ab einem Bestellwert von 50€"}
            buttonText={t("redeem_now")}
          />
        </div>
      );
    }
  };

  return (
    <div className="device-frame-wrapper">
      <DeviceFrameset device="iPhone X">
        <div className="device-frame-content">
          <div className="image-overlay">
            <img src={imageUrl} alt={name} className="device-frame-image" />
            <div className="image-gradient"></div>
          </div>

          <div className="device-frame-header">
            <p className="device-frame-type">{types}</p>
            <h2 className="device-frame-name">{name}</h2>
          </div>

          {/* Navigation mit Click-Handler */}
          <div className="nav-bar">
            <p
              className={`nav-bar-item ${
                activeTab === "coupons" ? "nav-bar-item-focus" : ""
              }`}
              onClick={() => setActiveTab("coupons")}
            >
              {t("coupons")}
            </p>
            <p
              className={`nav-bar-item ${
                activeTab === "reviews" ? "nav-bar-item-focus" : ""
              }`}
              onClick={() => setActiveTab("reviews")}
            >
              {t("info")}
            </p>
          </div>

          {/* Dynamischer Inhalt */}
          {renderContent()}
        </div>
      </DeviceFrameset>
    </div>
  );
};

export default DeviceFrame;
