interface IColors {
  tintColorLight: string;
  tintColorDark: string;
  basarColorLightRed: string;
  basarColorRed: string;
  basarColorDarkRed: string;
  basarColorGreen: string;
  basarColorLightGreen: string;
  basarColorDarkGreen: string;
  basarColorLightBlue: string;
  basarColorBlue: string;
  basarColorDarkBlue: string;
  basarColorLinkBlue: string;
  basarColorWhite: string;
  basarColorLightGrey: string;
  basarColorGrey: string;
  basarColorBlack: string;
  basarColorDarkGrey: string;
  basarColorBlackRed: string;
  basarColorBlackGreen: string;
  basarColorBlackBlue: string;
  basarColorBlackGrey: string;
}

const colors: IColors = {
  tintColorLight: "#2f95dc",
  tintColorDark: "#fff",
  basarColorLightRed: "#FAAFB6",
  basarColorRed: "#e13746",
  basarColorDarkRed: "#ee3f4e",
  basarColorGreen: "#17CF6B",
  basarColorLightGreen: "#C9E8D7",
  basarColorDarkGreen: "#0DA553",
  basarColorLightBlue: "#9CDFFC",
  basarColorBlue: "#2DABE0",
  basarColorDarkBlue: "#0A5373",
  basarColorLinkBlue: "#0085BE",
  basarColorWhite: "#FFFFFF",
  basarColorLightGrey: "#F5F5F5",
  basarColorGrey: "#d3d3d3",
  basarColorBlack: "#222222",
  basarColorDarkGrey: "#494949",
  basarColorBlackRed: "#190002",
  basarColorBlackGreen: "#00190C",
  basarColorBlackBlue: "#00090D",
  basarColorBlackGrey: "#333333",
};

export const COLORS = colors;