import axios from "axios";
import { ILocation } from "../types/IStoreDetails";
import { DealsResponse } from "../types/IDeals";

export const fetchDealsFromApi = async (
  language: string,
  pageNumber: number,
  size: number,
  parentCategory: string | null,
  location: ILocation
): Promise<DealsResponse> => {
  const response = await axios.get<DealsResponse>(
    `https://basar.digital/api/v1/stores/deals/nearby`,
    {
      params: {
        latitude: location.latitude,
        longitude: location.longitude,
        radius: 25000,
        page: pageNumber,
        size: size,
        language: language,
        parentCategory: parentCategory,
      },
    }
  );
  return response.data;
};