import React, { useState } from "react";
import "./BrowserDealsPage.css";
import { useDealData } from "../hooks/useDealData";
import { ILocation } from "../types/IStoreDetails";
import Autocomplete from "../components/OSM/OSM_Autocomplete";
import StoreDealCard from "../components/StoreDealCard";
import HeaderTextComponent from "../styles/HeaderTextComponent";
import { IRedeemLocation } from "../types/enums/IRedeemLocation";
import { IRespawnTime } from "../types/enums/IRespawnTime";

const BrowserDealsPage: React.FC = () => {
  const [city, setCity] = useState("");

  const DEFAULT_LOCATION: ILocation = {
    latitude: 51.481844,
    longitude: 7.216236,
  }; // Bochum Zentrum

  const [location, setLocation] = useState<ILocation>(DEFAULT_LOCATION);

  const { deals } = useDealData(location);

  // Dummy-Karte nur, wenn keine lokalen Angebote gefunden wurden
  const showNoResults = city && deals.length === 0;

  const dummyCard = {
    id: -1,
    storeId: -1,
    storeName: "Entdecke Angebote aus anderen Orten ",
    shopImgUrl:
      "https://zlpnrvwbzjulimalwcvs.supabase.co/storage/v1/object/sign/avatars/noStoresDiscoverOther.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJhdmF0YXJzL25vU3RvcmVzRGlzY292ZXJPdGhlci5wbmciLCJpYXQiOjE3MzkxMzg2MzksImV4cCI6MTAzNzkxMzg2Mzl9.-YHGcvmkwrWqPUZ1EZoOY5E7AAxPhfAPUhWobsU0UNI",
    storeAddress: {
      street: "Leider gibt es in deiner direkten Nähe keine aktiven Deals.",

      number: "",
      zipCode: "",
      city: "",
    },
    parentCategory: "",
    subCategories: [],
    coupon: {
      id: -1,
      header: "",
      body: "",
      targetProductValue: null,
      redeemLocation: IRedeemLocation.ON_SITE,
      respawnTime: IRespawnTime.DAILY,
      validDays: [],
      studentsOnly: false,
      benefitHeader: "Deals in anderen Städten",
      couponImg: "https://via.placeholder.com/300x200?text=Kein+Deal",
      dailyCount: 0,
      maxDailyCount: 0,
      currentRedemptions: 0,
      campaignId: undefined,
    },
  };

  const displayDeals = showNoResults ? [dummyCard, ...deals] : deals;

  console.log(deals.length)

  return (
    <div className="browser-deals-container">
      {/* 🔍 Überschrift */}
      <div className="browser-deals-two-column-container">
        <div className="browser-deals-header">
          <HeaderTextComponent level={1} title="">
            Entdecke Deals von lokalen Partnern.
          </HeaderTextComponent>
        </div>

        {/* Suchfeld */}
        <div className="autocomplete-container">
          <Autocomplete
            searchQuery={city}
            setSearchQuery={setCity}
            onAddressSelect={(place) => {
              setLocation({
                latitude: parseFloat(place.lat),
                longitude: parseFloat(place.lon),
              });
            }}
          />
        </div>
      </div>

      {/* 🏬 Deals Grid */}
      <div className="stores-grid">
        {displayDeals.map((store) => (
          <StoreDealCard
            key={store.coupon.id}
            store={store}
            className={store.storeId === -1 ? "dummy-card" : ""}
          />
        ))}
      </div>
    </div>
  );
};

export default BrowserDealsPage;
