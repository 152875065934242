import React, { useEffect, useState } from "react";
import "./ThreeStepComponent.css"; // Stildefinitionen für die Komponente
import HeaderTextComponent from "../styles/HeaderTextComponent";
import NormalTextComponent from "../styles/NormalTextComponent";

interface ThreeStepProps {
  title: string; // Titel der gesamten Komponente
  steps: {
    icon?: React.ReactNode;
    image: string;
    title: string;
    subtitle: string;
    description: string;
  }[];
}

const ThreeStepComponent: React.FC<ThreeStepProps> = ({ title, steps }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []); // Läuft nur beim ersten Render
  
  return (
    <div className="three-step-container">
      <HeaderTextComponent
        level={1}
        title={title}
        seoDescription="Wie man"
        fontWeight="bold"
      >
        {title}
      </HeaderTextComponent>

      <div className="steps-section">
        {steps.map((step, index) => (
          <div className="step-column" key={index}>
    
            <div className="step-content">
                <HeaderTextComponent level={2} title={step.title}>
                  {step.subtitle}
                </HeaderTextComponent>
            </div>
            <img
              src={step.image}
              alt={`Step ${index + 1}`}
              className={`step-image ${animate ? "slide-in" : ""}`}
            />
            <div className="step-description">
              <NormalTextComponent size="M" seoDescription={step.description}>
                {step.description}
              </NormalTextComponent>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThreeStepComponent;
