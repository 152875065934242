import React from "react";
import PartnerSearch from "../components/partner/PartnerSearch";
import "./PartnerPage.css";
import BenefitSection from "../components/partner/BenefitSection";
import HeaderTextComponent from "../styles/HeaderTextComponent";
import NormalTextComponent from "../styles/NormalTextComponent";
import mockupImage from "../assets/images/mockup2PhonesApp.png"; // Bildimport für das Mockup
import useTypedTranslation from "../i18n/useTypedTranslation";
import dS from "../designSystem/designSystem";
import VENDORS from "../assets/images/local_vendors.png";

import AppStoreButtons from "./elements/AppStoreButtons";
import SmartphoneVideoEmbed from "./SmarphoneVideoEmbed";

const PartnerPage: React.FC = () => {
  const { t } = useTypedTranslation();
  return (
    <div className="partner-page-container">
      <div className="two-grid-section-container">
        <div className="left-grid-container">
          <HeaderTextComponent
            level={1}
            className="partner-page-section-title"
            title={"Gratis neue Kunden erreichen"}
          >
            Gratis neue Kunden erreichen.
          </HeaderTextComponent>
          <p style={{ textAlign: "center" }}>
            Suche dein Geschäft & kontaktiere uns.
          </p>
          <PartnerSearch />
          <AppStoreButtons type={"partner"} />
        </div>

        <div className="right-grid-container image">
          <img src={VENDORS} alt="Your Alt Text" className="right-grid-image" />
        </div>
      </div>

      {/* Benefits */}

      <div className="section-container benefit">
        <div className="section-header">
          <HeaderTextComponent
            level={1}
            className="section-title"
            title={"Vorteile lokalen Handel"}
          >
            Vorteile
          </HeaderTextComponent>
        </div>
        <BenefitSection />
      </div>

      {/* App Mockup */}
      <div className="section-container">
        <div className="section-header">
          <HeaderTextComponent
            level={1}
            className="section-title invert"
            title={"Vorteile lokalen Handel"}
          >
            {t("start_now")}. {t("complete_free")}
          </HeaderTextComponent>
        </div>
        <div className="two-column-section">
          <div className="left-column">
            <div className="video-container">
              <SmartphoneVideoEmbed
                videoUrl="https://www.youtube.com/embed/eXUsRjNmyaY"
                color="black"
                device={"iPhone X"}
              />
            </div>
          </div>
          <div className="right-column">
            <HeaderTextComponent
              level={1}
              title="Lokale Schätze entdecken - BASAR"
              style={{ textAlign: "center" }}
              seoDescription="Entdecke lokale Vielfalt und spare mit BASAR"
            >
              <span
                style={{
                  color: dS.colors.basarColorDarkBlue,
                  textAlign: "center",
                }}
              >
                Gewinne
              </span>{" "}
              neue Kunden.
            </HeaderTextComponent>
            <NormalTextComponent
              size="L"
              title="Lokale Vielfalt entdecken - BASAR"
              seoDescription="Entdecke die Vielfalt des lokalen Handels mit BASAR"
            >
              - Schnell und unkompliziert loslegen.
            </NormalTextComponent>
            {/* App store download buttons */}
            <AppStoreButtons type={"partner"} />
          </div>
        </div>
      </div>

      <div className="section-container">
        <div className="section-header benefit">
          <HeaderTextComponent level={1} title={""} className="section-title">
            Wie sieht dein Geschäft auf der App aus?
          </HeaderTextComponent>
          <NormalTextComponent
            size="L"
            style={{ color: dS.colors.basarColorWhite }}
            title="Lokale Vielfalt entdecken - BASAR"
            seoDescription="Entdecke die Vielfalt des lokalen Handels mit BASAR"
          >
            Finde dein Geschäft & erhalte einen Vorgeschmack davon, wie dein
            Geschäft auf der App aussehen könnte.{" "}
          </NormalTextComponent>
          <div className="partner-search-container">
            <PartnerSearch />
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default PartnerPage;
