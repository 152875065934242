import React from "react";
import "./Footer.css"; // Stile für den Footer
import LOGO from "../assets/images/basarTextAndLogo.svg"; // Importiere das Logo-Bild
import useTypedTranslation from "../i18n/useTypedTranslation"; // Custom Hook für die Übersetzungen
import { useTranslation } from "react-i18next"; // Für die Sprachänderung

const Footer = () => {
  const { t } = useTypedTranslation();
  const { i18n } = useTranslation();

  // Funktion, um die Sprache zu ändern
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <footer className="footer">
      <div className="footer-grid">
        <div className="footer-column">
        
        </div>
        <div className="footer-column">
          <h3>{t("about")}</h3>
          <ul>
            <li>
              <a href="/contact">{t("contact")}</a>
            </li>
            <li>
              <a href="#">{t("frequently_asked_questions")}</a>
            </li>
            <li>
              <a href="#">{t("become_partner")}</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>{t("legal")}</h3>
          <ul>
            <li>
              <a href="/imprint">{t("Imprint")}</a>
            </li>
            <li>
              <a href="/privacy">{t("Datenschutz")}</a>
            </li>
            <li>
              <a href="/terms">{t("AGB")}</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>{t("social_media")}</h3>
          <ul>
            <li>
              <a
                href="https://www.instagram.com/basar_app/"
                target="_blank"
              >
                Instagram
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@basar_app" target="_blank">
                TikTok
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/basar-digital/"
                target="_blank"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Logo am Ende des Footers */}
      <div className="footer-logo">
        <img src={LOGO} alt="Logo" />
      </div>
    </footer>
  );
};

export default Footer;
