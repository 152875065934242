import React from "react";
import HeaderTextComponent from "../styles/HeaderTextComponent";
import NormalTextComponent from "../styles/NormalTextComponent";
import "./PricePage.module.css";
import CallToActionApp from "../components/CallToActionApp";

const PricePage: React.FC = () => {
  return (
    <div className="container">
      {/* Beta Announcement */}
      <div className="header-container">
        <HeaderTextComponent level={2} title={""} seoDescription={""}>
          Willkommen zur kostenlosen Beta-Version von BASAR!
        </HeaderTextComponent>
        <div className="subheader-container">
          <NormalTextComponent size={"L"} title={""} seoDescription={""}>
            Derzeit ist die gesamte App kostenlos – sowohl für Nutzer als auch
            für Partner. Nutzen Sie diese Gelegenheit, BASAR zu erleben und
            aktiv zur Verbesserung der App beizutragen.
          </NormalTextComponent>
        </div>
      </div>

      {/* Why Free Section */}
      <div className="header-container">
        <HeaderTextComponent level={2} title={""} seoDescription={""}>
          Warum kostenlos?
        </HeaderTextComponent>
        <div className="subheader-container">
          <NormalTextComponent size={"L"} title={""} seoDescription={""}>
            Wir bieten BASAR aktuell kostenfrei an, um wertvolles Feedback zu
            sammeln und den lokalen Handel zu unterstützen. Eure Meinung hilft
            uns dabei, die App weiter zu verbessern und Ihnen die besten
            Angebote direkt aus Ihrer Stadt zu liefern – und das völlig
            kostenfrei!
          </NormalTextComponent>
        </div>
      </div>

      {/* Call to Action */}
      <div>
        <CallToActionApp />
      </div>
    </div>
  );
};

export default PricePage;
