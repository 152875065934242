import React, { useState } from "react";
import { fetchOSMResults, OSMPlace } from "./fetchOSMResults";
import { MdClose, MdSearch } from "react-icons/md";
import dS from "../../designSystem/designSystem";
import "./OSM_Autocomplete.css"; // CSS-Datei für Styling

interface AutocompleteProps {
  searchQuery: string;
  setSearchQuery: (text: string) => void;
  onAddressSelect: (place: OSMPlace) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  searchQuery,
  setSearchQuery,
  onAddressSelect,
}) => {
  const [results, setResults] = useState<OSMPlace[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearchQueryChange = async (text: string) => {
    setSearchQuery(text);

    if (text.length > 2) {
      setLoading(true);
      try {
        const osmResults: OSMPlace[] = await new Promise((resolve) =>
          fetchOSMResults(text, resolve)
        );
        setResults(osmResults.slice(0, 4));
      } catch (error) {
        console.error("Fehler beim Abrufen der Ergebnisse:", error);
        setResults([]);
      } finally {
        setLoading(false);
      }
    } else {
      setResults([]);
      setLoading(false);
    }
  };

  const handleSelect = (item: OSMPlace) => {
    onAddressSelect(item);
    setSearchQuery(item.name);
    setResults([]);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setResults([]);
    setLoading(false);
  };

  return (
    <div className="autocomplete-container">
      {/* 🔎 Input-Feld */}
      <div className="osm-input-container">
        <MdSearch
          className="osm-search-icon"
          size={22}
          color={dS.colors.basarColorGrey}
        />
        <input
          className="osm-search-input"
          type="text"
          placeholder="Stadt suchen..."
          value={searchQuery}
          onChange={(e) => handleSearchQueryChange(e.target.value)}
        />
        {searchQuery.length > 0 && (
          <button className="clear-button" onClick={handleClearSearch}>
            <MdClose size={22} color={dS.colors.basarColorGrey} />
          </button>
        )}
      </div>

      {/* 📍 Suchergebnisse OSM */}
      {results.length > 0 && (
        <ul className="results-list">
          {results.map((item) => (
            <li
              key={item.place_id}
              className="result-item"
              onClick={() => handleSelect(item)}
            >
              <span className="result-text">{item.name}</span>
              <span className="address-text">
                {item.address.road
                  ? ` (${item.address.road ?? ""} ${
                      item.address.house_number ?? ""
                    }, ${item.address.city ?? ""} ${
                      item.address.postcode ?? ""
                    })`
                  : ""}
              </span>
            </li>
          ))}
        </ul>
      )}

      {/* ⏳ Ladeindikator */}
      {loading && results.length === 0 && (
        <p className="loading-text">Lädt...</p>
      )}
    </div>
  );
};

export default Autocomplete;
