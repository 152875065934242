import React, { useState, useEffect } from 'react';

const SitemapPage: React.FC = () => {
  const [xmlContent, setXmlContent] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSitemap = async () => {
      try {
        const response = await fetch('https://basar.digital/api/v1/sitemap.xml');
        if (!response.ok) {
          throw new Error(`Fehler beim Abrufen der Sitemap: ${response.status}`);
        }
        const text = await response.text();
        setXmlContent(text);
      } catch (error) {
        setError('Fehler beim Laden der Sitemap');
        console.error('Fehler beim Abrufen der Sitemap:', error);
      }
    };

    fetchSitemap();
  }, []);

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      {xmlContent}
    </pre>
  );
};

export default SitemapPage;
