import * as React from "react";
const AppleBadgeBlack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    zoomAndPan="magnify"
    viewBox="0 0 384 115.499997"
    height={154}
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <defs>
      <clipPath id="f78d86db73">
        <path
          d="M 1.824219 2.582031 L 382.175781 2.582031 L 382.175781 112.355469 L 1.824219 112.355469 Z M 1.824219 2.582031 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <path
      fill="#000000"
      d="M 379.984375 99.699219 C 379.984375 105.546875 375.144531 110.285156 369.15625 110.285156 L 14.855469 110.285156 C 8.875 110.285156 4.015625 105.546875 4.015625 99.699219 L 4.015625 15.257812 C 4.015625 9.410156 8.875 4.65625 14.855469 4.65625 L 369.15625 4.65625 C 375.144531 4.65625 379.984375 9.410156 379.984375 15.257812 Z M 379.984375 99.699219 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <g clipPath="url(#f78d86db73)">
      <path
        fill="#a6a6a6"
        d="M 368.015625 4.78125 C 374.535156 4.78125 379.839844 9.953125 379.839844 16.304688 L 379.839844 98.636719 C 379.839844 104.988281 374.535156 110.160156 368.015625 110.160156 L 15.984375 110.160156 C 9.464844 110.160156 4.160156 104.988281 4.160156 98.636719 L 4.160156 16.304688 C 4.160156 9.953125 9.464844 4.78125 15.984375 4.78125 Z M 368.015625 2.582031 L 15.984375 2.582031 C 8.246094 2.582031 1.90625 8.757812 1.90625 16.304688 L 1.90625 98.636719 C 1.90625 106.179688 8.246094 112.355469 15.984375 112.355469 L 368.015625 112.355469 C 375.753906 112.355469 382.09375 106.179688 382.09375 98.636719 L 382.09375 16.304688 C 382.09375 8.757812 375.753906 2.582031 368.015625 2.582031 Z M 368.015625 2.582031 "
        fillOpacity={1}
        fillRule="nonzero"
      />
    </g>
    <path
      fill="#ffffff"
      d="M 86.753906 56.878906 C 86.671875 48.03125 94.183594 43.730469 94.527344 43.53125 C 90.273438 37.480469 83.675781 36.65625 81.359375 36.589844 C 75.820312 36.023438 70.445312 39.820312 67.625 39.820312 C 64.746094 39.820312 60.402344 36.644531 55.71875 36.738281 C 49.691406 36.828125 44.050781 40.226562 40.957031 45.511719 C 34.578125 56.277344 39.335938 72.097656 45.449219 80.796875 C 48.511719 85.0625 52.082031 89.820312 56.761719 89.65625 C 61.339844 89.46875 63.046875 86.8125 68.570312 86.8125 C 74.042969 86.8125 75.648438 89.65625 80.417969 89.546875 C 85.328125 89.46875 88.421875 85.265625 91.375 80.964844 C 94.910156 76.082031 96.328125 71.269531 96.382812 71.019531 C 96.265625 70.980469 86.847656 67.476562 86.753906 56.878906 Z M 86.753906 56.878906 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 77.742188 30.867188 C 80.203125 27.867188 81.886719 23.785156 81.417969 19.644531 C 77.855469 19.796875 73.398438 22.046875 70.832031 24.976562 C 68.566406 27.5625 66.535156 31.804688 67.058594 35.785156 C 71.0625 36.078125 75.167969 33.820312 77.742188 30.867188 Z M 77.742188 30.867188 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 140.042969 30.050781 C 140.042969 33.28125 139.046875 35.710938 137.0625 37.347656 C 135.222656 38.851562 132.609375 39.605469 129.226562 39.605469 C 127.542969 39.605469 126.109375 39.535156 124.90625 39.390625 L 124.90625 21.746094 C 126.476562 21.496094 128.164062 21.371094 129.992188 21.371094 C 133.214844 21.371094 135.640625 22.054688 137.285156 23.421875 C 139.121094 24.964844 140.042969 27.175781 140.042969 30.050781 Z M 136.929688 30.132812 C 136.929688 28.035156 136.359375 26.433594 135.222656 25.3125 C 134.082031 24.195312 132.421875 23.636719 130.234375 23.636719 C 129.304688 23.636719 128.511719 23.695312 127.855469 23.824219 L 127.855469 37.238281 C 128.222656 37.292969 128.886719 37.320312 129.851562 37.320312 C 132.109375 37.320312 133.851562 36.703125 135.082031 35.484375 C 136.308594 34.257812 136.929688 32.472656 136.929688 30.132812 Z M 136.929688 30.132812 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 156.539062 32.875 C 156.539062 34.859375 155.957031 36.492188 154.789062 37.769531 C 153.570312 39.085938 151.949219 39.742188 149.929688 39.742188 C 147.980469 39.742188 146.429688 39.113281 145.269531 37.851562 C 144.113281 36.589844 143.539062 35 143.539062 33.085938 C 143.539062 31.085938 144.132812 29.4375 145.328125 28.164062 C 146.519531 26.886719 148.128906 26.246094 150.144531 26.246094 C 152.09375 26.246094 153.660156 26.875 154.847656 28.136719 C 155.976562 29.359375 156.539062 30.941406 156.539062 32.875 Z M 153.480469 32.964844 C 153.480469 31.773438 153.214844 30.75 152.691406 29.894531 C 152.066406 28.863281 151.1875 28.347656 150.042969 28.347656 C 148.855469 28.347656 147.953125 28.863281 147.335938 29.894531 C 146.804688 30.75 146.542969 31.789062 146.542969 33.015625 C 146.542969 34.210938 146.808594 35.234375 147.335938 36.089844 C 147.972656 37.121094 148.863281 37.636719 150.015625 37.636719 C 151.136719 37.636719 152.023438 37.113281 152.660156 36.0625 C 153.207031 35.191406 153.480469 34.160156 153.480469 32.964844 Z M 153.480469 32.964844 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 178.664062 26.511719 L 174.507812 39.445312 L 171.804688 39.445312 L 170.085938 33.832031 C 169.652344 32.425781 169.292969 31.035156 169.019531 29.648438 L 168.96875 29.648438 C 168.710938 31.074219 168.355469 32.464844 167.898438 33.832031 L 166.070312 39.445312 L 163.335938 39.445312 L 159.429688 26.511719 L 162.464844 26.511719 L 163.964844 32.660156 C 164.328125 34.113281 164.625 35.503906 164.863281 36.8125 L 164.917969 36.8125 C 165.140625 35.730469 165.5 34.355469 166.015625 32.6875 L 167.898438 26.515625 L 170.304688 26.515625 L 172.109375 32.558594 C 172.546875 34.027344 172.898438 35.449219 173.175781 36.816406 L 173.257812 36.816406 C 173.457031 35.484375 173.753906 34.066406 174.15625 32.558594 L 175.765625 26.515625 L 178.664062 26.515625 Z M 178.664062 26.511719 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 193.964844 39.445312 L 191.015625 39.445312 L 191.015625 32.035156 C 191.015625 29.753906 190.125 28.613281 188.339844 28.613281 C 187.460938 28.613281 186.757812 28.925781 186.207031 29.554688 C 185.664062 30.183594 185.386719 30.925781 185.386719 31.773438 L 185.386719 39.445312 L 182.4375 39.445312 L 182.4375 30.210938 C 182.4375 29.070312 182.402344 27.839844 182.328125 26.507812 L 184.921875 26.507812 L 185.0625 28.527344 L 185.144531 28.527344 C 185.484375 27.898438 186 27.382812 186.671875 26.964844 C 187.472656 26.484375 188.367188 26.238281 189.347656 26.238281 C 190.585938 26.238281 191.613281 26.628906 192.4375 27.414062 C 193.453125 28.371094 193.964844 29.796875 193.964844 31.699219 Z M 193.964844 39.445312 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 199.15625 20.574219 L 202.105469 20.574219 L 202.105469 39.445312 L 199.15625 39.445312 Z M 199.15625 20.574219 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 219.484375 32.875 C 219.484375 34.859375 218.898438 36.492188 217.730469 37.769531 C 216.511719 39.085938 214.890625 39.742188 212.867188 39.742188 C 210.917969 39.742188 209.367188 39.113281 208.210938 37.851562 C 207.054688 36.589844 206.476562 35 206.476562 33.085938 C 206.476562 31.085938 207.070312 29.4375 208.265625 28.164062 C 209.460938 26.886719 211.066406 26.246094 213.085938 26.246094 C 215.035156 26.246094 216.601562 26.875 217.785156 28.136719 C 218.914062 29.359375 219.484375 30.941406 219.484375 32.875 Z M 216.417969 32.964844 C 216.417969 31.773438 216.152344 30.75 215.625 29.894531 C 215.011719 28.863281 214.125 28.347656 212.980469 28.347656 C 211.792969 28.347656 210.890625 28.863281 210.273438 29.894531 C 209.742188 30.75 209.484375 31.789062 209.484375 33.015625 C 209.484375 34.210938 209.746094 35.234375 210.273438 36.089844 C 210.914062 37.121094 211.804688 37.636719 212.953125 37.636719 C 214.082031 37.636719 214.964844 37.113281 215.597656 36.0625 C 216.144531 35.191406 216.417969 34.160156 216.417969 32.964844 Z M 216.417969 32.964844 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 233.765625 39.445312 L 231.117188 39.445312 L 230.894531 37.957031 L 230.8125 37.957031 C 229.90625 39.148438 228.613281 39.742188 226.933594 39.742188 C 225.683594 39.742188 224.667969 39.347656 223.90625 38.570312 C 223.210938 37.863281 222.867188 36.980469 222.867188 35.933594 C 222.867188 34.355469 223.539062 33.148438 224.902344 32.316406 C 226.257812 31.480469 228.167969 31.074219 230.625 31.09375 L 230.625 30.851562 C 230.625 29.148438 229.707031 28.296875 227.871094 28.296875 C 226.5625 28.296875 225.40625 28.613281 224.410156 29.253906 L 223.808594 27.367188 C 225.042969 26.621094 226.566406 26.246094 228.363281 26.246094 C 231.835938 26.246094 233.574219 28.03125 233.574219 31.597656 L 233.574219 36.363281 C 233.574219 37.65625 233.636719 38.683594 233.765625 39.445312 Z M 230.699219 35 L 230.699219 33.003906 C 227.445312 32.949219 225.820312 33.824219 225.820312 35.617188 C 225.820312 36.289062 226.003906 36.792969 226.382812 37.132812 C 226.761719 37.46875 227.25 37.636719 227.824219 37.636719 C 228.476562 37.636719 229.078125 37.4375 229.628906 37.039062 C 230.1875 36.636719 230.523438 36.128906 230.652344 35.507812 C 230.683594 35.367188 230.699219 35.195312 230.699219 35 Z M 230.699219 35 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 250.535156 39.445312 L 247.914062 39.445312 L 247.777344 37.371094 L 247.695312 37.371094 C 246.859375 38.953125 245.4375 39.742188 243.433594 39.742188 C 241.832031 39.742188 240.5 39.128906 239.445312 37.90625 C 238.390625 36.679688 237.859375 35.09375 237.859375 33.140625 C 237.859375 31.046875 238.4375 29.347656 239.582031 28.054688 C 240.695312 26.847656 242.058594 26.246094 243.683594 26.246094 C 245.464844 26.246094 246.710938 26.828125 247.421875 28 L 247.476562 28 L 247.476562 20.574219 L 250.429688 20.574219 L 250.429688 35.960938 C 250.429688 37.222656 250.464844 38.382812 250.535156 39.445312 Z M 247.476562 33.992188 L 247.476562 31.835938 C 247.476562 31.460938 247.449219 31.15625 247.398438 30.929688 C 247.230469 30.234375 246.871094 29.65625 246.328125 29.1875 C 245.777344 28.714844 245.117188 28.480469 244.355469 28.480469 C 243.25 28.480469 242.390625 28.90625 241.757812 29.761719 C 241.132812 30.613281 240.8125 31.703125 240.8125 33.03125 C 240.8125 34.3125 241.113281 35.351562 241.71875 36.148438 C 242.355469 37 243.21875 37.425781 244.296875 37.425781 C 245.265625 37.425781 246.039062 37.070312 246.628906 36.363281 C 247.199219 35.703125 247.476562 34.914062 247.476562 33.992188 Z M 247.476562 33.992188 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 275.777344 32.875 C 275.777344 34.859375 275.195312 36.492188 274.027344 37.769531 C 272.804688 39.085938 271.1875 39.742188 269.164062 39.742188 C 267.21875 39.742188 265.664062 39.113281 264.507812 37.851562 C 263.351562 36.589844 262.777344 35 262.777344 33.085938 C 262.777344 31.085938 263.367188 29.4375 264.566406 28.164062 C 265.757812 26.886719 267.359375 26.246094 269.386719 26.246094 C 271.332031 26.246094 272.902344 26.875 274.085938 28.136719 C 275.210938 29.359375 275.777344 30.941406 275.777344 32.875 Z M 272.71875 32.964844 C 272.71875 31.773438 272.457031 30.75 271.925781 29.894531 C 271.304688 28.863281 270.425781 28.347656 269.277344 28.347656 C 268.097656 28.347656 267.195312 28.863281 266.570312 29.894531 C 266.042969 30.75 265.78125 31.789062 265.78125 33.015625 C 265.78125 34.210938 266.042969 35.234375 266.570312 36.089844 C 267.210938 37.121094 268.101562 37.636719 269.246094 37.636719 C 270.375 37.636719 271.261719 37.113281 271.898438 36.0625 C 272.441406 35.191406 272.71875 34.160156 272.71875 32.964844 Z M 272.71875 32.964844 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 291.648438 39.445312 L 288.699219 39.445312 L 288.699219 32.035156 C 288.699219 29.753906 287.808594 28.613281 286.019531 28.613281 C 285.144531 28.613281 284.4375 28.925781 283.890625 29.554688 C 283.347656 30.183594 283.070312 30.925781 283.070312 31.773438 L 283.070312 39.445312 L 280.117188 39.445312 L 280.117188 30.210938 C 280.117188 29.070312 280.082031 27.839844 280.011719 26.507812 L 282.605469 26.507812 L 282.738281 28.527344 L 282.820312 28.527344 C 283.171875 27.898438 283.679688 27.382812 284.355469 26.964844 C 285.15625 26.484375 286.050781 26.238281 287.03125 26.238281 C 288.265625 26.238281 289.296875 26.628906 290.117188 27.414062 C 291.136719 28.371094 291.648438 29.796875 291.648438 31.699219 Z M 291.648438 39.445312 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 311.511719 28.664062 L 308.261719 28.664062 L 308.261719 34.949219 C 308.261719 36.546875 308.839844 37.347656 309.980469 37.347656 C 310.511719 37.347656 310.949219 37.304688 311.296875 37.210938 L 311.371094 39.390625 C 310.789062 39.605469 310.019531 39.714844 309.078125 39.714844 C 307.914062 39.714844 307.007812 39.367188 306.351562 38.675781 C 305.691406 37.984375 305.363281 36.820312 305.363281 35.1875 L 305.363281 28.664062 L 303.425781 28.664062 L 303.425781 26.511719 L 305.363281 26.511719 L 305.363281 24.136719 L 308.253906 23.289062 L 308.253906 26.507812 L 311.507812 26.507812 L 311.507812 28.664062 Z M 311.511719 28.664062 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 327.132812 39.445312 L 324.183594 39.445312 L 324.183594 32.089844 C 324.183594 29.773438 323.292969 28.613281 321.507812 28.613281 C 320.140625 28.613281 319.203125 29.285156 318.691406 30.628906 C 318.605469 30.914062 318.554688 31.257812 318.554688 31.664062 L 318.554688 39.445312 L 315.605469 39.445312 L 315.605469 20.574219 L 318.554688 20.574219 L 318.554688 28.375 L 318.609375 28.375 C 319.539062 26.953125 320.871094 26.246094 322.597656 26.246094 C 323.820312 26.246094 324.832031 26.632812 325.632812 27.417969 C 326.632812 28.390625 327.132812 29.839844 327.132812 31.757812 Z M 327.132812 39.445312 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 343.25 32.367188 C 343.25 32.886719 343.210938 33.316406 343.144531 33.671875 L 334.289062 33.671875 C 334.328125 34.949219 334.753906 35.921875 335.570312 36.597656 C 336.320312 37.203125 337.285156 37.503906 338.46875 37.503906 C 339.777344 37.503906 340.972656 37.304688 342.050781 36.890625 L 342.511719 38.890625 C 341.253906 39.421875 339.769531 39.6875 338.054688 39.6875 C 335.996094 39.6875 334.378906 39.101562 333.210938 37.917969 C 332.03125 36.738281 331.445312 35.15625 331.445312 33.167969 C 331.445312 31.214844 331.992188 29.59375 333.089844 28.296875 C 334.230469 26.914062 335.777344 26.222656 337.730469 26.222656 C 339.636719 26.222656 341.089844 26.914062 342.070312 28.296875 C 342.859375 29.394531 343.25 30.753906 343.25 32.367188 Z M 340.4375 31.625 C 340.457031 30.769531 340.265625 30.035156 339.863281 29.414062 C 339.351562 28.613281 338.570312 28.214844 337.515625 28.214844 C 336.550781 28.214844 335.765625 28.601562 335.164062 29.386719 C 334.675781 30.011719 334.386719 30.753906 334.289062 31.625 Z M 340.4375 31.625 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 152.980469 89.039062 L 146.582031 89.039062 L 143.082031 78.3125 L 130.90625 78.3125 L 127.566406 89.039062 L 121.339844 89.039062 L 133.40625 52.519531 L 140.859375 52.519531 Z M 142.023438 73.8125 L 138.859375 64.277344 C 138.519531 63.300781 137.894531 61.007812 136.96875 57.394531 L 136.855469 57.394531 C 136.488281 58.949219 135.890625 61.242188 135.074219 64.277344 L 131.964844 73.8125 Z M 142.023438 73.8125 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 184.007812 75.546875 C 184.007812 80.027344 182.765625 83.566406 180.28125 86.167969 C 178.054688 88.480469 175.296875 89.636719 171.996094 89.636719 C 168.4375 89.636719 165.878906 88.390625 164.324219 85.898438 L 164.210938 85.898438 L 164.210938 99.769531 L 158.203125 99.769531 L 158.203125 71.375 C 158.203125 68.558594 158.132812 65.667969 157.984375 62.707031 L 163.265625 62.707031 L 163.597656 66.878906 L 163.710938 66.878906 C 165.710938 63.734375 168.753906 62.164062 172.828125 62.164062 C 176.019531 62.164062 178.679688 63.394531 180.804688 65.847656 C 182.941406 68.304688 184.007812 71.542969 184.007812 75.546875 Z M 177.890625 75.765625 C 177.890625 73.199219 177.296875 71.085938 176.113281 69.425781 C 174.8125 67.691406 173.070312 66.824219 170.882812 66.824219 C 169.402344 66.824219 168.058594 67.304688 166.855469 68.257812 C 165.648438 69.21875 164.859375 70.472656 164.488281 72.023438 C 164.304688 72.75 164.214844 73.34375 164.214844 73.808594 L 164.214844 78.199219 C 164.214844 80.117188 164.8125 81.730469 166.019531 83.054688 C 167.222656 84.371094 168.792969 85.03125 170.71875 85.03125 C 172.980469 85.03125 174.738281 84.183594 176 82.488281 C 177.257812 80.785156 177.890625 78.546875 177.890625 75.765625 Z M 177.890625 75.765625 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 215.089844 75.546875 C 215.089844 80.027344 213.847656 83.566406 211.363281 86.167969 C 209.140625 88.480469 206.375 89.636719 203.078125 89.636719 C 199.519531 89.636719 196.964844 88.390625 195.40625 85.898438 L 195.292969 85.898438 L 195.292969 99.769531 L 189.289062 99.769531 L 189.289062 71.375 C 189.289062 68.558594 189.214844 65.667969 189.066406 62.707031 L 194.347656 62.707031 L 194.683594 66.878906 L 194.796875 66.878906 C 196.796875 63.734375 199.832031 62.164062 203.917969 62.164062 C 207.097656 62.164062 209.761719 63.394531 211.898438 65.847656 C 214.023438 68.304688 215.089844 71.542969 215.089844 75.546875 Z M 208.972656 75.765625 C 208.972656 73.199219 208.378906 71.085938 207.191406 69.425781 C 205.894531 67.691406 204.15625 66.824219 201.964844 66.824219 C 200.484375 66.824219 199.136719 67.304688 197.933594 68.257812 C 196.730469 69.21875 195.945312 70.472656 195.574219 72.023438 C 195.390625 72.75 195.292969 73.34375 195.292969 73.808594 L 195.292969 78.199219 C 195.292969 80.117188 195.898438 81.730469 197.097656 83.054688 C 198.300781 84.371094 199.867188 85.03125 201.800781 85.03125 C 204.0625 85.03125 205.824219 84.183594 207.078125 82.488281 C 208.34375 80.785156 208.972656 78.546875 208.972656 75.765625 Z M 208.972656 75.765625 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 249.84375 78.800781 C 249.84375 81.90625 248.734375 84.433594 246.515625 86.382812 C 244.070312 88.515625 240.671875 89.582031 236.304688 89.582031 C 232.269531 89.582031 229.039062 88.824219 226.59375 87.304688 L 227.984375 82.429688 C 230.617188 83.984375 233.511719 84.761719 236.664062 84.761719 C 238.925781 84.761719 240.683594 84.265625 241.949219 83.269531 C 243.207031 82.277344 243.835938 80.941406 243.835938 79.277344 C 243.835938 77.796875 243.316406 76.546875 242.277344 75.535156 C 241.242188 74.523438 239.515625 73.582031 237.109375 72.710938 C 230.542969 70.328125 227.265625 66.832031 227.265625 62.238281 C 227.265625 59.234375 228.417969 56.773438 230.71875 54.859375 C 233.011719 52.941406 236.066406 51.980469 239.894531 51.980469 C 243.300781 51.980469 246.136719 52.5625 248.398438 53.714844 L 246.898438 58.488281 C 244.789062 57.367188 242.394531 56.808594 239.722656 56.808594 C 237.609375 56.808594 235.960938 57.3125 234.78125 58.324219 C 233.78125 59.226562 233.277344 60.328125 233.277344 61.632812 C 233.277344 63.078125 233.851562 64.269531 234.996094 65.210938 C 235.996094 66.078125 237.8125 67.015625 240.449219 68.027344 C 243.675781 69.292969 246.042969 70.773438 247.566406 72.46875 C 249.085938 74.160156 249.84375 76.273438 249.84375 78.800781 Z M 249.84375 78.800781 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 269.695312 67.097656 L 263.074219 67.097656 L 263.074219 79.882812 C 263.074219 83.136719 264.242188 84.757812 266.578125 84.757812 C 267.652344 84.757812 268.542969 84.667969 269.246094 84.488281 L 269.414062 88.929688 C 268.230469 89.363281 266.671875 89.582031 264.742188 89.582031 C 262.371094 89.582031 260.519531 88.875 259.183594 87.464844 C 257.847656 86.058594 257.179688 83.6875 257.179688 80.367188 L 257.179688 67.09375 L 253.234375 67.09375 L 253.234375 62.703125 L 257.179688 62.703125 L 257.179688 57.878906 L 263.074219 56.144531 L 263.074219 62.703125 L 269.695312 62.703125 Z M 269.695312 67.097656 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 299.554688 75.65625 C 299.554688 79.707031 298.367188 83.027344 295.996094 85.625 C 293.511719 88.300781 290.207031 89.636719 286.09375 89.636719 C 282.128906 89.636719 278.972656 88.355469 276.613281 85.792969 C 274.261719 83.230469 273.082031 79.992188 273.082031 76.09375 C 273.082031 72.011719 274.296875 68.671875 276.726562 66.070312 C 279.148438 63.46875 282.425781 62.167969 286.539062 62.167969 C 290.503906 62.167969 293.695312 63.449219 296.105469 66.015625 C 298.40625 68.503906 299.554688 71.71875 299.554688 75.65625 Z M 293.328125 75.847656 C 293.328125 73.417969 292.792969 71.335938 291.710938 69.597656 C 290.457031 67.496094 288.65625 66.445312 286.320312 66.445312 C 283.910156 66.445312 282.078125 67.496094 280.820312 69.597656 C 279.742188 71.335938 279.207031 73.453125 279.207031 75.957031 C 279.207031 78.382812 279.742188 80.46875 280.820312 82.203125 C 282.117188 84.304688 283.929688 85.351562 286.273438 85.351562 C 288.5625 85.351562 290.363281 84.285156 291.664062 82.148438 C 292.765625 80.378906 293.328125 78.269531 293.328125 75.847656 Z M 293.328125 75.847656 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 319.070312 67.851562 C 318.476562 67.746094 317.84375 67.691406 317.175781 67.691406 C 315.066406 67.691406 313.429688 68.464844 312.285156 70.019531 C 311.285156 71.394531 310.78125 73.128906 310.78125 75.226562 L 310.78125 89.039062 L 304.78125 89.039062 L 304.839844 71 C 304.839844 67.964844 304.761719 65.203125 304.613281 62.710938 L 309.839844 62.710938 L 310.0625 67.746094 L 310.230469 67.746094 C 310.863281 66.015625 311.859375 64.621094 313.230469 63.578125 C 314.566406 62.636719 316.015625 62.164062 317.570312 62.164062 C 318.125 62.164062 318.628906 62.203125 319.070312 62.273438 Z M 319.070312 67.851562 "
      fillOpacity={1}
      fillRule="nonzero"
    />
    <path
      fill="#ffffff"
      d="M 345.921875 74.625 C 345.921875 75.675781 345.851562 76.558594 345.707031 77.28125 L 327.695312 77.28125 C 327.765625 79.882812 328.632812 81.871094 330.304688 83.246094 C 331.824219 84.46875 333.785156 85.085938 336.195312 85.085938 C 338.863281 85.085938 341.296875 84.671875 343.484375 83.839844 L 344.425781 87.902344 C 341.871094 88.988281 338.847656 89.53125 335.367188 89.53125 C 331.175781 89.53125 327.886719 88.328125 325.492188 85.925781 C 323.105469 83.523438 321.90625 80.300781 321.90625 76.253906 C 321.90625 72.285156 323.019531 68.980469 325.246094 66.335938 C 327.578125 63.527344 330.730469 62.117188 334.695312 62.117188 C 338.59375 62.117188 341.539062 63.527344 343.542969 66.335938 C 345.125 68.578125 345.921875 71.34375 345.921875 74.625 Z M 340.199219 73.109375 C 340.238281 71.375 339.84375 69.878906 339.03125 68.613281 C 337.992188 66.984375 336.398438 66.171875 334.246094 66.171875 C 332.285156 66.171875 330.6875 66.964844 329.46875 68.558594 C 328.46875 69.824219 327.871094 71.339844 327.695312 73.105469 L 340.199219 73.105469 Z M 340.199219 73.109375 "
      fillOpacity={1}
      fillRule="nonzero"
    />
  </svg>
);
export default AppleBadgeBlack;
