export enum IRespawnTime {
  DAILY = "DAILY",
  THREE_DAYS = "THREE_DAYS",
  WEEKLY = "WEEKLY",
  THIRTY_DAYS = "THIRTY_DAYS",
  NINETY_DAYS = "NINETY_DAYS",
  HUNDRED_EIGHTY_DAYS = "HUNDRED_EIGHTY_DAYS",
  ONE_YEAR = "ONE_YEAR",
}

export const RespawnTimeData = {
  [IRespawnTime.THIRTY_DAYS]: {
    value: IRespawnTime.THIRTY_DAYS,
  },
  [IRespawnTime.NINETY_DAYS]: {
    value: IRespawnTime.NINETY_DAYS,
  },
  [IRespawnTime.WEEKLY]: { value: IRespawnTime.WEEKLY },
};
