import React from "react";
import Accordion from "../components/Accordion";
import useTypedTranslation from "../i18n/useTypedTranslation";
import styles from "./FAQPage.module.css"; // Create this CSS module file

const FAQPage: React.FC = () => {
  const { t } = useTypedTranslation();
  const faqData = [
    {
      category: t("faq.general.category"),
      questions: [
        {
          question: t("faq.general.question_1"),
          answer: t("faq.general.answer_1"),
        },
      ],
    },
    {
      category: t("faq.price.category"),
      questions: [
        {
          question: t("faq.price.question_1"),
          answer: t("faq.price.answer_1"),
        },
        {
          question: t("faq.price.question_2"),
          answer: t("faq.price.answer_2"),
        },
      ],
    },
    {
      category: t("faq.book_deal.category"),
      questions: [
        {
          question: t("faq.book_deal.question_1"),
          answer: t("faq.book_deal.answer_1"),
        },
        {
          question: t("faq.book_deal.question_2"),
          answer: t("faq.book_deal.answer_2"),
        },
      ],
    },
  ];

  return (
    <div className={styles.faqContainer}>
      <h1 className={styles.mainTitle}>{t("frequently_asked_questions")}</h1>
      <div className={styles.faqContent}>
        {faqData.map((category, index) => (
          <div key={index} className={styles.categorySection}>
            <h2 className={styles.categoryTitle}>{category.category}</h2>
            <div className={styles.questionsContainer}>
              {category.questions.map((faq, i) => (
                <Accordion
                  key={i}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.contactSection}>
        <label className={styles.contactLabel}>{t("contact")}</label>
        <button className={styles.contactButton}>{t("contact")}</button>
      </div>
    </div>
  );
};

export default FAQPage;
