import { useState, useEffect } from 'react';
import { IOpeningTimes, DaysOfWeek } from '../types/IOpeningTimes';

// Angenommen, IOpeningTimes hat diese Struktur:

const useCurrentTime = (openingTimes: IOpeningTimes) => {
  const [currentlyOpen, setCurrentlyOpen] = useState(false);

  useEffect(() => {
    const checkIfOpen = () => {
      const now = new Date();
      const currentDay = now.toLocaleString('en-US', { weekday: 'long' }).toLowerCase() as DaysOfWeek; // Typumwandlung
      const currentTime = now.getHours() * 60 + now.getMinutes(); // Zeit in Minuten

      const times = openingTimes[currentDay];
      if (times && times.openingTime && times.closingTime) {
        const [openingHour, openingMinute] = times.openingTime.split(':').map(Number);
        const [closingHour, closingMinute] = times.closingTime.split(':').map(Number);
        const openingTimeInMinutes = openingHour * 60 + openingMinute;
        const closingTimeInMinutes = closingHour * 60 + closingMinute;

        return currentTime >= openingTimeInMinutes && currentTime < closingTimeInMinutes;
      }
      return false;
    };

    setCurrentlyOpen(checkIfOpen());

    const interval = setInterval(() => {
      setCurrentlyOpen(checkIfOpen());
    }, 60000); // Überprüfung jede Minute

    return () => clearInterval(interval);
  }, [openingTimes]);

  return currentlyOpen;
};

export default useCurrentTime;