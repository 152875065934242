import { useState, useEffect } from 'react';

const useCurrentDay = () => {
  const [currentDay, setCurrentDay] = useState('');

  useEffect(() => {
    const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const dayIndex = new Date().getDay();
    setCurrentDay(daysOfWeek[dayIndex]);
  }, []);

  return currentDay;
};

export default useCurrentDay;
