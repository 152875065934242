import useTypedTranslation from "../../i18n/useTypedTranslation";
import { ICategory } from "../../types/IUseCategories";

const useCategories = () => {
  const { t } = useTypedTranslation();

  const categoriesArray: ICategory[] = [
    {
      id: 1,
      backendMainCategory: "GROCERY",
      mainCategoryFriendlyName: t("categories.grocery"),
      mainCategoryImage: require("../../assets/images/category-images/grocery.png"),
      subCategories: [
        {
          subId: 0,
          frontendSubCategoryName: "BUTCHER_GROCERY",
          subCategoryFriendlyName: t("subcategories.BUTCHER_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 1,
          frontendSubCategoryName: "BAKERY_GROCERY",
          subCategoryFriendlyName: t("subcategories.BAKERY_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 2,
          frontendSubCategoryName: "ASIAN_GROCERY",
          subCategoryFriendlyName: t("subcategories.ASIAN_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 3,
          frontendSubCategoryName: "EAST_EUROPEAN_GROCERY",
          subCategoryFriendlyName: t("subcategories.EAST_EUROPEAN_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 4,
          frontendSubCategoryName: "FISH_MARKET_GROCERY",
          subCategoryFriendlyName: t("subcategories.FISH_MARKET_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 5,
          frontendSubCategoryName: "BEVERAGE_GROCERY",
          subCategoryFriendlyName: t("subcategories.BEVERAGE_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 6,
          frontendSubCategoryName: "INDIAN_GROCERY",
          subCategoryFriendlyName: t("subcategories.INDIAN_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 7,
          frontendSubCategoryName: "BIO_GROCERY",
          subCategoryFriendlyName: t("subcategories.BIO_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 8,
          frontendSubCategoryName: "AFRICAN_GROCERY",
          subCategoryFriendlyName: t("subcategories.AFRICAN_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 9,
          frontendSubCategoryName: "CONVENIENCE_STORE",
          subCategoryFriendlyName: t("subcategories.CONVENIENCE_STORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 10,
          frontendSubCategoryName: "ORIENTAL_GROCERY",
          subCategoryFriendlyName: t("subcategories.ORIENTAL_GROCERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 11,
          frontendSubCategoryName: "TURKISH_SUPERMARKET",
          subCategoryFriendlyName: t("subcategories.TURKISH_SUPERMARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 12,
          frontendSubCategoryName: "LATIN_AMERICAN_SUPERMARKET",
          subCategoryFriendlyName: t("subcategories.LATIN_AMERICAN_SUPERMARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 13,
          frontendSubCategoryName: "MIDDLE_EASTERN_SUPERMARKET",
          subCategoryFriendlyName: t("subcategories.MIDDLE_EASTERN_SUPERMARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 14,
          frontendSubCategoryName: "RUSSIAN_SUPERMARKET",
          subCategoryFriendlyName: t("subcategories.RUSSIAN_SUPERMARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 15,
          frontendSubCategoryName: "POLISH_SUPERMARKET",
          subCategoryFriendlyName: t("subcategories.POLISH_SUPERMARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 16,
          frontendSubCategoryName: "MEDITERRANEAN_SUPERMARKET",
          subCategoryFriendlyName: t("subcategories.MEDITERRANEAN_SUPERMARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 17,
          frontendSubCategoryName: "VEGAN_VEGETARIAN_SUPERMARKET",
          subCategoryFriendlyName: t("subcategories.VEGAN_VEGETARIAN_SUPERMARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 18,
          frontendSubCategoryName: "HALAL_MARKET",
          subCategoryFriendlyName: t("subcategories.HALAL_MARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 19,
          frontendSubCategoryName: "KOSHER_SUPERMARKET",
          subCategoryFriendlyName: t("subcategories.KOSHER_SUPERMARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 20,
          frontendSubCategoryName: "SOUTH_ASIAN_SUPERMARKET",
          subCategoryFriendlyName: t("subcategories.SOUTH_ASIAN_SUPERMARKET"),
          subCategoryKeywords: [],
        },
        {
          subId: 21,
          frontendSubCategoryName: "CANDY_SHOP",
          subCategoryFriendlyName: t("subcategories.CANDY_SHOP"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 2,
      backendMainCategory: "FARM_SHOP",
      mainCategoryFriendlyName: t("categories.farm_shop"),
      mainCategoryImage: require("../../assets/images/category-images/farm_shop.png"),
      subCategories: [
        {
          subId: 22,
          frontendSubCategoryName: "DAIRY_FARM_SHOP",
          subCategoryFriendlyName: t("subcategories.DAIRY_FARM_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 23,
          frontendSubCategoryName: "FRUIT_VEGETABLE_FARM_SHOP",
          subCategoryFriendlyName: t("subcategories.FRUIT_VEGETABLE_FARM_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 24,
          frontendSubCategoryName: "IMKER_FARM_SHOP",
          subCategoryFriendlyName: t("subcategories.IMKER_FARM_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 25,
          frontendSubCategoryName: "GIFTS_FARM_SHOP",
          subCategoryFriendlyName: t("subcategories.GIFTS_FARM_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 26,
          frontendSubCategoryName: "EGGS_FARM_SHOP",
          subCategoryFriendlyName: t("subcategories.EGGS_FARM_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 27,
          frontendSubCategoryName: "MEAT_FARM_SHOP",
          subCategoryFriendlyName: t("subcategories.MEAT_FARM_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 28,
          frontendSubCategoryName: "REGIONAL_PRODUCTS",
          subCategoryFriendlyName: t("subcategories.REGIONAL_PRODUCTS"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 3,
      backendMainCategory: "RESTAURANTS",
      mainCategoryFriendlyName: t("categories.restaurants"),
      mainCategoryImage: require("../../assets/images/category-images/restaurants.png"),
      subCategories: [
        {
          subId: 29,
          frontendSubCategoryName: "TURKISH_CUISINE",
          subCategoryFriendlyName: t("subcategories.TURKISH_CUISINE"),
          subCategoryKeywords: [],
        },
        {
          subId: 30,
          frontendSubCategoryName: "GERMAN_CUISINE",
          subCategoryFriendlyName: t("subcategories.GERMAN_CUISINE"),
          subCategoryKeywords: [],
        },
        {
          subId: 31,
          frontendSubCategoryName: "DOENER",
          subCategoryFriendlyName: t("subcategories.DOENER"),
          subCategoryKeywords: [],
        },
        {
          subId: 32,
          frontendSubCategoryName: "ARABIC_CUISINE",
          subCategoryFriendlyName: t("subcategories.ARABIC_CUISINE"),
          subCategoryKeywords: [],
        },
        {
          subId: 33,
          frontendSubCategoryName: "FRENCH_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.FRENCH_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 34,
          frontendSubCategoryName: "SPANISH_TAPAS_BAR",
          subCategoryFriendlyName: t("subcategories.SPANISH_TAPAS_BAR"),
          subCategoryKeywords: [],
        },
        {
          subId: 35,
          frontendSubCategoryName: "GREEK_TAVERN",
          subCategoryFriendlyName: t("subcategories.GREEK_TAVERN"),
          subCategoryKeywords: [],
        },
        {
          subId: 36,
          frontendSubCategoryName: "MEXICAN_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.MEXICAN_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 37,
          frontendSubCategoryName: "INDIAN_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.INDIAN_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 38,
          frontendSubCategoryName: "SUSHI_BAR",
          subCategoryFriendlyName: t("subcategories.SUSHI_BAR"),
          subCategoryKeywords: [],
        },
        {
          subId: 39,
          frontendSubCategoryName: "STEAKHOUSE",
          subCategoryFriendlyName: t("subcategories.STEAKHOUSE"),
          subCategoryKeywords: [],
        },
        {
          subId: 40,
          frontendSubCategoryName: "VEGETARIAN_VEGAN_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.VEGETARIAN_VEGAN_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 41,
          frontendSubCategoryName: "FOOD_TRUCK",
          subCategoryFriendlyName: t("subcategories.FOOD_TRUCK"),
          subCategoryKeywords: [],
        },
        {
          subId: 42,
          frontendSubCategoryName: "FUSION_CUISINE",
          subCategoryFriendlyName: t("subcategories.FUSION_CUISINE"),
          subCategoryKeywords: [],
        },
        {
          subId: 43,
          frontendSubCategoryName: "PATISSERIE",
          subCategoryFriendlyName: t("subcategories.PATISSERIE"),
          subCategoryKeywords: [],
        },
        {
          subId: 44,
          frontendSubCategoryName: "HALAL_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.HALAL_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 45,
          frontendSubCategoryName: "ASIAN_RESTAURANTS",
          subCategoryFriendlyName: t("subcategories.ASIAN_RESTAURANTS"),
          subCategoryKeywords: [],
        },
        {
          subId: 46,
          frontendSubCategoryName: "SEAFOOD_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.SEAFOOD_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 47,
          frontendSubCategoryName: "ITALIAN_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.ITALIAN_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 48,
          frontendSubCategoryName: "BURGER_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.BURGER_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 49,
          frontendSubCategoryName: "VEGETARIAN_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.VEGETARIAN_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 50,
          frontendSubCategoryName: "MEDITERRANEAN_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.MEDITERRANEAN_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 51,
          frontendSubCategoryName: "BISTRO_IMBISS_RESTAURANT",
          subCategoryFriendlyName: t("subcategories.BISTRO_IMBISS_RESTAURANT"),
          subCategoryKeywords: [],
        },
        {
          subId: 52,
          frontendSubCategoryName: "BARBEQUE",
          subCategoryFriendlyName: t("subcategories.BARBEQUE"),
          subCategoryKeywords: [],
        },
        {
          subId: 53,
          frontendSubCategoryName: "BREAKFAST_BRUNCH",
          subCategoryFriendlyName: t("subcategories.BREAKFAST_BRUNCH"),
          subCategoryKeywords: [],
        },
        {
          subId: 54,
          frontendSubCategoryName: "BUFFETS",
          subCategoryFriendlyName: t("subcategories.BUFFETS"),
          subCategoryKeywords: [],
        },
        {
          subId: 55,
          frontendSubCategoryName: "MEAT",
          subCategoryFriendlyName: t("subcategories.MEAT"),
          subCategoryKeywords: [],
        },
        {
          subId: 56,
          frontendSubCategoryName: "FRIED",
          subCategoryFriendlyName: t("subcategories.FRIED"),
          subCategoryKeywords: [],
        },
        {
          subId: 57,
          frontendSubCategoryName: "FAST_FOOD",
          subCategoryFriendlyName: t("subcategories.FAST_FOOD"),
          subCategoryKeywords: [],
        },
        {
          subId: 58,
          frontendSubCategoryName: "PIZZA",
          subCategoryFriendlyName: t("subcategories.PIZZA"),
          subCategoryKeywords: [],
        },
        {
          subId: 59,
          frontendSubCategoryName: "LOW_CARB",
          subCategoryFriendlyName: t("subcategories.LOW_CARB"),
          subCategoryKeywords: [],
        },
        {
          subId: 60,
          frontendSubCategoryName: "SANDWICH_BAGUETTE",
          subCategoryFriendlyName: t("subcategories.SANDWICH_BAGUETTE"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 4,
      backendMainCategory: "HAIR_SALON",
      mainCategoryFriendlyName: t("categories.hairSalon"),
      mainCategoryImage: require("../../assets/images/category-images/hairSalon.png"),
      subCategories: [
        {
          subId: 61,
          frontendSubCategoryName: "MEN_HAIR_SALON",
          subCategoryFriendlyName: t("subcategories.MEN_HAIR_SALON"),
          subCategoryKeywords: [],
        },
        {
          subId: 62,
          frontendSubCategoryName: "KIDS_HAIR_SALON",
          subCategoryFriendlyName: t("subcategories.KIDS_HAIR_SALON"),
          subCategoryKeywords: [],
        },
        {
          subId: 63,
          frontendSubCategoryName: "WOMEN_HAIR_SALON",
          subCategoryFriendlyName: t("subcategories.WOMEN_HAIR_SALON"),
          subCategoryKeywords: [],
        },
        {
          subId: 64,
          frontendSubCategoryName: "BRIDE_AND_EVENT",
          subCategoryFriendlyName: t("subcategories.BRIDE_AND_EVENT"),
          subCategoryKeywords: [],
        },
        {
          subId: 65,
          frontendSubCategoryName: "AFRO_BARBER",
          subCategoryFriendlyName: t("subcategories.AFRO_BARBER"),
          subCategoryKeywords: [],
        },
        {
          subId: 66,
          frontendSubCategoryName: "HAIR_SPECIALIST",
          subCategoryFriendlyName: t("subcategories.HAIR_SPECIALIST"),
          subCategoryKeywords: [],
        },
        {
          subId: 67,
          frontendSubCategoryName: "PERMANENT_HAIR_REMOVAL",
          subCategoryFriendlyName: t("subcategories.PERMANENT_HAIR_REMOVAL"),
          subCategoryKeywords: [],
        },
        {
          subId: 68,
          frontendSubCategoryName: "HAIR_REPLACEMENT",
          subCategoryFriendlyName: t("subcategories.HAIR_REPLACEMENT"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 5,
      backendMainCategory: "BEAUTY_AND_WELLNESS",
      mainCategoryFriendlyName: t("categories.beauty_wellness"),
      mainCategoryImage: require("../../assets/images/category-images/beauty_wellness.png"),
      subCategories: [
        {
          subId: 69,
          frontendSubCategoryName: "SPA_WELLNESS",
          subCategoryFriendlyName: t("subcategories.SPA_WELLNESS"),
          subCategoryKeywords: [],
        },
        {
          subId: 70,
          frontendSubCategoryName: "MASSAGE_CENTER",
          subCategoryFriendlyName: t("subcategories.MASSAGE_CENTER"),
          subCategoryKeywords: [],
        },
        {
          subId: 71,
          frontendSubCategoryName: "FACIAL_TREATMENTS",
          subCategoryFriendlyName: t("subcategories.FACIAL_TREATMENTS"),
          subCategoryKeywords: [],
        },
        {
          subId: 72,
          frontendSubCategoryName: "COSMETIC",
          subCategoryFriendlyName: t("subcategories.COSMETIC"),
          subCategoryKeywords: [],
        },
        {
          subId: 73,
          frontendSubCategoryName: "COSMETICS_BEAUTY_SUPPLY",
          subCategoryFriendlyName: t("subcategories.COSMETICS_BEAUTY_SUPPLY"),
          subCategoryKeywords: [],
        },
        {
          subId: 74,
          frontendSubCategoryName: "MAKEUP_ARTISTS",
          subCategoryFriendlyName: t("subcategories.MAKEUP_ARTISTS"),
          subCategoryKeywords: [],
        },
        {
          subId: 75,
          frontendSubCategoryName: "MANICURE_PEDICURE",
          subCategoryFriendlyName: t("subcategories.MANICURE_PEDICURE"),
          subCategoryKeywords: [],
        },
        {
          subId: 76,
          frontendSubCategoryName: "HAIR_TREATMENTS",
          subCategoryFriendlyName: t("subcategories.HAIR_TREATMENTS"),
          subCategoryKeywords: [],
        },
        {
          subId: 77,
          frontendSubCategoryName: "HAIR_REMOVAL",
          subCategoryFriendlyName: t("subcategories.HAIR_REMOVAL"),
          subCategoryKeywords: [],
        },
        {
          subId: 78,
          frontendSubCategoryName: "THERAPIES",
          subCategoryFriendlyName: t("subcategories.THERAPIES"),
          subCategoryKeywords: [],
        },
        {
          subId: 79,
          frontendSubCategoryName: "TEETH_WHITENING",
          subCategoryFriendlyName: t("subcategories.TEETH_WHITENING"),
          subCategoryKeywords: [],
        },
        {
          subId: 80,
          frontendSubCategoryName: "NAIL_SALON",
          subCategoryFriendlyName: t("subcategories.NAIL_SALON"),
          subCategoryKeywords: [],
        },
        {
          subId: 81,
          frontendSubCategoryName: "ACUPUNCTURE",
          subCategoryFriendlyName: t("subcategories.ACUPUNCTURE"),
          subCategoryKeywords: [],
        },
        {
          subId: 82,
          frontendSubCategoryName: "SCOOPING",
          subCategoryFriendlyName: t("subcategories.SCOOPING"),
          subCategoryKeywords: [],
        },
        {
          subId: 83,
          frontendSubCategoryName: "BARBER_SERVICES",
          subCategoryFriendlyName: t("subcategories.BARBER_SERVICES"),
          subCategoryKeywords: [],
        },
        {
          subId: 84,
          frontendSubCategoryName: "SOLARIUM",
          subCategoryFriendlyName: t("subcategories.SOLARIUM"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 6,
      backendMainCategory: "CAFE_AND_DESSERT",
      mainCategoryFriendlyName: t("categories.cafe_and_dessert"),
      mainCategoryImage: require("../../assets/images/category-images/cafe_and_dessert.png"),
      subCategories: [
        {
          subId: 85,
          frontendSubCategoryName: "ICE_CREAM_PARLOR",
          subCategoryFriendlyName: t("subcategories.ICE_CREAM_PARLOR"),
          subCategoryKeywords: [],
        },
        {
          subId: 86,
          frontendSubCategoryName: "BAKERY_CAFE",
          subCategoryFriendlyName: t("subcategories.BAKERY_CAFE"),
          subCategoryKeywords: [],
        },
        {
          subId: 87,
          frontendSubCategoryName: "DESSERT_BAR",
          subCategoryFriendlyName: t("subcategories.DESSERT_BAR"),
          subCategoryKeywords: [],
        },
        {
          subId: 88,
          frontendSubCategoryName: "CREPES",
          subCategoryFriendlyName: t("subcategories.CREPES"),
          subCategoryKeywords: [],
        },
        {
          subId: 89,
          frontendSubCategoryName: "WAFFLES",
          subCategoryFriendlyName: t("subcategories.WAFFLES"),
          subCategoryKeywords: [],
        },
        {
          subId: 90,
          frontendSubCategoryName: "BUBBLE_TEA",
          subCategoryFriendlyName: t("subcategories.BUBBLE_TEA"),
          subCategoryKeywords: [],
        },
        {
          subId: 91,
          frontendSubCategoryName: "SWEET_PASTRY",
          subCategoryFriendlyName: t("subcategories.SWEET_PASTRY"),
          subCategoryKeywords: [],
        },
        {
          subId: 92,
          frontendSubCategoryName: "JUICES",
          subCategoryFriendlyName: t("subcategories.JUICES"),
          subCategoryKeywords: [],
        },
        {
          subId: 93,
          frontendSubCategoryName: "TEA_HOUSE",
          subCategoryFriendlyName: t("subcategories.TEA_HOUSE"),
          subCategoryKeywords: [],
        },
        {
          subId: 94,
          frontendSubCategoryName: "VEGAN_CAFE",
          subCategoryFriendlyName: t("subcategories.VEGAN_CAFE"),
          subCategoryKeywords: [],
        },
        {
          subId: 95,
          frontendSubCategoryName: "COFFEE_HOUSE",
          subCategoryFriendlyName: t("subcategories.COFFEE_HOUSE"),
          subCategoryKeywords: [],
        },
        {
          subId: 96,
          frontendSubCategoryName: "TURKISH_COFFEE",
          subCategoryFriendlyName: t("subcategories.TURKISH_COFFEE"),
          subCategoryKeywords: [],
        },
        {
          subId: 97,
          frontendSubCategoryName: "ARABIC_COFFEE",
          subCategoryFriendlyName: t("subcategories.ARABIC_COFFEE"),
          subCategoryKeywords: [],
        },
        {
          subId: 98,
          frontendSubCategoryName: "MATCHA_CAFE",
          subCategoryFriendlyName: t("subcategories.MATCHA_CAFE"),
          subCategoryKeywords: [],
        },
        {
          subId: 99,
          frontendSubCategoryName: "KAKAO_BAR",
          subCategoryFriendlyName: t("subcategories.KAKAO_BAR"),
          subCategoryKeywords: [],
        },
        {
          subId: 100,
          frontendSubCategoryName: "DONUT_SHOP",
          subCategoryFriendlyName: t("subcategories.DONUT_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 101,
          frontendSubCategoryName: "CHURROS",
          subCategoryFriendlyName: t("subcategories.CHURROS"),
          subCategoryKeywords: [],
        },
        {
          subId: 102,
          frontendSubCategoryName: "MOCHI_DESSERT",
          subCategoryFriendlyName: t("subcategories.MOCHI_DESSERT"),
          subCategoryKeywords: [],
        },
        {
          subId: 103,
          frontendSubCategoryName: "TIRAMISU_BAR",
          subCategoryFriendlyName: t("subcategories.TIRAMISU_BAR"),
          subCategoryKeywords: [],
        },
        {
          subId: 104,
          frontendSubCategoryName: "FROZEN_YOGURT",
          subCategoryFriendlyName: t("subcategories.FROZEN_YOGURT"),
          subCategoryKeywords: [],
        },
        {
          subId: 105,
          frontendSubCategoryName: "FRENCH_PASTRY",
          subCategoryFriendlyName: t("subcategories.FRENCH_PASTRY"),
          subCategoryKeywords: [],
        },
        {
          subId: 106,
          frontendSubCategoryName: "TURKISH_DESSERTS",
          subCategoryFriendlyName: t("subcategories.TURKISH_DESSERTS"),
          subCategoryKeywords: [],
        },
        {
          subId: 107,
          frontendSubCategoryName: "ARABIC_SWEETS",
          subCategoryFriendlyName: t("subcategories.ARABIC_SWEETS"),
          subCategoryKeywords: [],
        },
        {
          subId: 108,
          frontendSubCategoryName: "PERSIAN_DESSERTS",
          subCategoryFriendlyName: t("subcategories.PERSIAN_DESSERTS"),
          subCategoryKeywords: [],
        },
        {
          subId: 109,
          frontendSubCategoryName: "INDIAN_SWEETS",
          subCategoryFriendlyName: t("subcategories.INDIAN_SWEETS"),
          subCategoryKeywords: [],
        },
        {
          subId: 110,
          frontendSubCategoryName: "CHEESECAKE_BAR",
          subCategoryFriendlyName: t("subcategories.CHEESECAKE_BAR"),
          subCategoryKeywords: [],
        },
        {
          subId: 111,
          frontendSubCategoryName: "ICE_ROLLS",
          subCategoryFriendlyName: t("subcategories.ICE_ROLLS"),
          subCategoryKeywords: [],
        },
        {
          subId: 112,
          frontendSubCategoryName: "CROFFLE_SHOP",
          subCategoryFriendlyName: t("subcategories.CROFFLE_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 113,
          frontendSubCategoryName: "MILLE_CREPE_CAKE",
          subCategoryFriendlyName: t("subcategories.MILLE_CREPE_CAKE"),
          subCategoryKeywords: [],
        },
        {
          subId: 114,
          frontendSubCategoryName: "SOFT_SERVE",
          subCategoryFriendlyName: t("subcategories.SOFT_SERVE"),
          subCategoryKeywords: [],
        },
        {
          subId: 115,
          frontendSubCategoryName: "ORIENTAL",
          subCategoryFriendlyName: t("subcategories.ORIENTAL"),
          subCategoryKeywords: [],
        },
        {
          subId: 116,
          frontendSubCategoryName: "AMERICAN",
          subCategoryFriendlyName: t("subcategories.AMERICAN"),
          subCategoryKeywords: [],
        },
        {
          subId: 117,
          frontendSubCategoryName: "EUROPEAN",
          subCategoryFriendlyName: t("subcategories.EUROPEAN"),
          subCategoryKeywords: [],
        },
        {
          subId: 118,
          frontendSubCategoryName: "ASIAN",
          subCategoryFriendlyName: t("subcategories.ASIAN"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 7,
      backendMainCategory: "ACTIVITIES",
      mainCategoryFriendlyName: t("categories.activities"),
      mainCategoryImage: require("../../assets/images/category-images/activities.png"),
      subCategories: [
        {
          subId: 119,
          frontendSubCategoryName: "SPORTS_AND_FITNESS",
          subCategoryFriendlyName: t("subcategories.SPORTS_AND_FITNESS"),
          subCategoryKeywords: [],
        },
        {
          subId: 120,
          frontendSubCategoryName: "GAMES_AND_ENTERTAINMENT",
          subCategoryFriendlyName: t("subcategories.GAMES_AND_ENTERTAINMENT"),
          subCategoryKeywords: [],
        },
        {
          subId: 121,
          frontendSubCategoryName: "NATURE_AND_OUTDOOR",
          subCategoryFriendlyName: t("subcategories.NATURE_AND_OUTDOOR"),
          subCategoryKeywords: [],
        },
        {
          subId: 122,
          frontendSubCategoryName: "CREATIVE_ACTIVITIES",
          subCategoryFriendlyName: t("subcategories.CREATIVE_ACTIVITIES"),
          subCategoryKeywords: [],
        },
        {
          subId: 123,
          frontendSubCategoryName: "LEARNING_AND_EDUCATION",
          subCategoryFriendlyName: t("subcategories.LEARNING_AND_EDUCATION"),
          subCategoryKeywords: [],
        },
        {
          subId: 124,
          frontendSubCategoryName: "ADVENTURE_AND_ACTION",
          subCategoryFriendlyName: t("subcategories.ADVENTURE_AND_ACTION"),
          subCategoryKeywords: [],
        },
        {
          subId: 125,
          frontendSubCategoryName: "RELAXATION_AND_WELLNESS",
          subCategoryFriendlyName: t("subcategories.RELAXATION_AND_WELLNESS"),
          subCategoryKeywords: [],
        },
        {
          subId: 126,
          frontendSubCategoryName: "KIDS_ACTIVITIES",
          subCategoryFriendlyName: t("subcategories.KIDS_ACTIVITIES"),
          subCategoryKeywords: [],
        },
        {
          subId: 127,
          frontendSubCategoryName: "CULTURAL_EVENTS",
          subCategoryFriendlyName: t("subcategories.CULTURAL_EVENTS"),
          subCategoryKeywords: [],
        },
        {
          subId: 128,
          frontendSubCategoryName: "CINEMA",
          subCategoryFriendlyName: t("subcategories.CINEMA"),
          subCategoryKeywords: [],
        },
        {
          subId: 129,
          frontendSubCategoryName: "THEATER",
          subCategoryFriendlyName: t("subcategories.THEATER"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 8,
      backendMainCategory: "FLOWERS_AND_GIFTS",
      mainCategoryFriendlyName: t("categories.flowers_and_gifts"),
      mainCategoryImage: require("../../assets/images/category-images/flowers_and_gifts.png"),
      subCategories: [
        {
          subId: 130,
          frontendSubCategoryName: "FLOWER_SHOP",
          subCategoryFriendlyName: t("subcategories.FLOWER_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 131,
          frontendSubCategoryName: "GIFT_SHOP",
          subCategoryFriendlyName: t("subcategories.GIFT_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 132,
          frontendSubCategoryName: "EVENT_DECORATORS",
          subCategoryFriendlyName: t("subcategories.EVENT_DECORATORS"),
          subCategoryKeywords: [],
        },
        {
          subId: 133,
          frontendSubCategoryName: "INDOOR_PLANTS",
          subCategoryFriendlyName: t("subcategories.INDOOR_PLANTS"),
          subCategoryKeywords: [],
        },
        {
          subId: 134,
          frontendSubCategoryName: "OUTDOOR_PLANTS",
          subCategoryFriendlyName: t("subcategories.OUTDOOR_PLANTS"),
          subCategoryKeywords: [],
        },
        {
          subId: 135,
          frontendSubCategoryName: "BALLON_AND_PARTY_DECO",
          subCategoryFriendlyName: t("subcategories.BALLON_AND_PARTY_DECO"),
          subCategoryKeywords: [],
        },
        {
          subId: 136,
          frontendSubCategoryName: "HANDMADE",
          subCategoryFriendlyName: t("subcategories.HANDMADE"),
          subCategoryKeywords: [],
        },
        {
          subId: 137,
          frontendSubCategoryName: "CANDLES_AND_FRAGRANCES",
          subCategoryFriendlyName: t("subcategories.CANDLES_AND_FRAGRANCES"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 9,
      backendMainCategory: "AUTOMOTIVE",
      mainCategoryFriendlyName: t("categories.automotive"),
      mainCategoryImage: require("../../assets/images/category-images/automotive.png"),
      subCategories: [
        {
          subId: 138,
          frontendSubCategoryName: "CAR_REPAIR",
          subCategoryFriendlyName: t("subcategories.CAR_REPAIR"),
          subCategoryKeywords: [],
        },
        {
          subId: 139,
          frontendSubCategoryName: "CAR_WASH",
          subCategoryFriendlyName: t("subcategories.CAR_WASH"),
          subCategoryKeywords: [],
        },
        {
          subId: 140,
          frontendSubCategoryName: "TIRE_SHOP",
          subCategoryFriendlyName: t("subcategories.TIRE_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 141,
          frontendSubCategoryName: "AUTO_DETAILING",
          subCategoryFriendlyName: t("subcategories.AUTO_DETAILING"),
          subCategoryKeywords: [],
        },
        {
          subId: 142,
          frontendSubCategoryName: "BODY_AND_PAINT",
          subCategoryFriendlyName: t("subcategories.BODY_AND_PAINT"),
          subCategoryKeywords: [],
        },
        {
          subId: 143,
          frontendSubCategoryName: "CAR_RENTAL",
          subCategoryFriendlyName: t("subcategories.CAR_RENTAL"),
          subCategoryKeywords: [],
        },
        {
          subId: 144,
          frontendSubCategoryName: "AUTO_GLASS",
          subCategoryFriendlyName: t("subcategories.AUTO_GLASS"),
          subCategoryKeywords: [],
        },
        {
          subId: 145,
          frontendSubCategoryName: "INSPECTION",
          subCategoryFriendlyName: t("subcategories.INSPECTION"),
          subCategoryKeywords: [],
        },
        {
          subId: 146,
          frontendSubCategoryName: "OIL_CHANGE",
          subCategoryFriendlyName: t("subcategories.OIL_CHANGE"),
          subCategoryKeywords: [],
        },
        {
          subId: 147,
          frontendSubCategoryName: "MOTORCYCLE",
          subCategoryFriendlyName: t("subcategories.MOTORCYCLE"),
          subCategoryKeywords: [],
        },
        {
          subId: 148,
          frontendSubCategoryName: "AUTO_ACCESSORIES",
          subCategoryFriendlyName: t("subcategories.AUTO_ACCESSORIES"),
          subCategoryKeywords: [],
        },
        {
          subId: 149,
          frontendSubCategoryName: "AUTO_TUNING",
          subCategoryFriendlyName: t("subcategories.AUTO_TUNING"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 10,
      backendMainCategory: "HOME_AND_GARDEN",
      mainCategoryFriendlyName: t("categories.home_and_garden"),
      mainCategoryImage: require("../../assets/images/category-images/home_and_garden.png"),
      subCategories: [
        {
          subId: 150,
          frontendSubCategoryName: "HOME_DECOR",
          subCategoryFriendlyName: t("subcategories.HOME_DECOR"),
          subCategoryKeywords: [],
        },
        {
          subId: 151,
          frontendSubCategoryName: "FURNITURE_STORE",
          subCategoryFriendlyName: t("subcategories.FURNITURE_STORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 152,
          frontendSubCategoryName: "GARDEN_CENTER",
          subCategoryFriendlyName: t("subcategories.GARDEN_CENTER"),
          subCategoryKeywords: [],
        },
        {
          subId: 153,
          frontendSubCategoryName: "HOME_IMPROVEMENT",
          subCategoryFriendlyName: t("subcategories.HOME_IMPROVEMENT"),
          subCategoryKeywords: [],
        },
        {
          subId: 154,
          frontendSubCategoryName: "LIGHTING_STORE",
          subCategoryFriendlyName: t("subcategories.LIGHTING_STORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 155,
          frontendSubCategoryName: "APPLIANCE_STORE",
          subCategoryFriendlyName: t("subcategories.APPLIANCE_STORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 156,
          frontendSubCategoryName: "KITCHEN_BATH_STORE",
          subCategoryFriendlyName: t("subcategories.KITCHEN_BATH_STORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 157,
          frontendSubCategoryName: "SECURITY_SMART_HOME",
          subCategoryFriendlyName: t("subcategories.SECURITY_SMART_HOME"),
          subCategoryKeywords: [],
        },
        {
          subId: 158,
          frontendSubCategoryName: "TEXTIL",
          subCategoryFriendlyName: t("subcategories.TEXTIL"),
          subCategoryKeywords: [],
        },
        {
          subId: 159,
          frontendSubCategoryName: "DIY_HOME_AND_GARDEN",
          subCategoryFriendlyName: t("subcategories.DIY_HOME_AND_GARDEN"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 11,
      backendMainCategory: "NON_FOOD_RETAIL",
      mainCategoryFriendlyName: t("categories.non_food_retail"),
      mainCategoryImage: require("../../assets/images/category-images/non_food_retail.png"),
      subCategories: [
        {
          subId: 160,
          frontendSubCategoryName: "CLOTHING_STORE",
          subCategoryFriendlyName: t("subcategories.CLOTHING_STORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 161,
          frontendSubCategoryName: "HANDY_SHOP",
          subCategoryFriendlyName: t("subcategories.HANDY_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 162,
          frontendSubCategoryName: "ELECTRONICS_STORE",
          subCategoryFriendlyName: t("subcategories.ELECTRONICS_STORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 163,
          frontendSubCategoryName: "BOOKSTORE",
          subCategoryFriendlyName: t("subcategories.BOOKSTORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 164,
          frontendSubCategoryName: "PHARMACY",
          subCategoryFriendlyName: t("subcategories.PHARMACY"),
          subCategoryKeywords: [],
        },
        {
          subId: 165,
          frontendSubCategoryName: "MEDICAL_SUPPLY",
          subCategoryFriendlyName: t("subcategories.MEDICAL_SUPPLY"),
          subCategoryKeywords: [],
        },
        {
          subId: 166,
          frontendSubCategoryName: "JEWLERY",
          subCategoryFriendlyName: t("subcategories.JEWLERY"),
          subCategoryKeywords: [],
        },
        {
          subId: 167,
          frontendSubCategoryName: "TOY_STORE",
          subCategoryFriendlyName: t("subcategories.TOY_STORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 168,
          frontendSubCategoryName: "MUSIC_STORE",
          subCategoryFriendlyName: t("subcategories.MUSIC_STORE"),
          subCategoryKeywords: [],
        },
        {
          subId: 169,
          frontendSubCategoryName: "SECONDHAND_STORES",
          subCategoryFriendlyName: t("subcategories.SECONDHAND_STORES"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 12,
      backendMainCategory: "SPORT_HEALTH",
      mainCategoryFriendlyName: t("categories.sport_and_health"),
      mainCategoryImage: require("../../assets/images/category-images/sport_and_health.png"),
      subCategories: [
        {
          subId: 170,
          frontendSubCategoryName: "FITNESS_AND_GYM",
          subCategoryFriendlyName: t("subcategories.FITNESS_AND_GYM"),
          subCategoryKeywords: [],
        },
        {
          subId: 171,
          frontendSubCategoryName: "FITNESS_SHOP",
          subCategoryFriendlyName: t("subcategories.FITNESS_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 172,
          frontendSubCategoryName: "SPORT_APPAREL",
          subCategoryFriendlyName: t("subcategories.SPORT_APPAREL"),
          subCategoryKeywords: [],
        },
        {
          subId: 173,
          frontendSubCategoryName: "HEALTHY_LIVING",
          subCategoryFriendlyName: t("subcategories.HEALTHY_LIVING"),
          subCategoryKeywords: [],
        },
        {
          subId: 174,
          frontendSubCategoryName: "COURSES",
          subCategoryFriendlyName: t("subcategories.COURSES"),
          subCategoryKeywords: [],
        },
        {
          subId: 175,
          frontendSubCategoryName: "SPORT_EQUIPMENT",
          subCategoryFriendlyName: t("subcategories.SPORT_EQUIPMENT"),
          subCategoryKeywords: [],
        },
        {
          subId: 176,
          frontendSubCategoryName: "INDOOR_SPORT",
          subCategoryFriendlyName: t("subcategories.INDOOR_SPORT"),
          subCategoryKeywords: [],
        },
        {
          subId: 177,
          frontendSubCategoryName: "OUTDOOR_SPORT",
          subCategoryFriendlyName: t("subcategories.OUTDOOR_SPORT"),
          subCategoryKeywords: [],
        },
        {
          subId: 178,
          frontendSubCategoryName: "EXTREME_SPORT",
          subCategoryFriendlyName: t("subcategories.EXTREME_SPORT"),
          subCategoryKeywords: [],
        },
        {
          subId: 179,
          frontendSubCategoryName: "OTHER_SPORT",
          subCategoryFriendlyName: t("subcategories.OTHER_SPORT"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 13,
      backendMainCategory: "SERVICES",
      mainCategoryFriendlyName: t("categories.services"),
      mainCategoryImage: require("../../assets/images/category-images/services.png"),
      subCategories: [
        {
          subId: 180,
          frontendSubCategoryName: "TRAVEL_AGENCY",
          subCategoryFriendlyName: t("subcategories.TRAVEL_AGENCY"),
          subCategoryKeywords: [],
        },
        {
          subId: 181,
          frontendSubCategoryName: "EDUCATION",
          subCategoryFriendlyName: t("subcategories.EDUCATION"),
          subCategoryKeywords: [],
        },
        {
          subId: 182,
          frontendSubCategoryName: "DRIVING_SCHOOL",
          subCategoryFriendlyName: t("subcategories.DRIVING_SCHOOL"),
          subCategoryKeywords: [],
        },
        {
          subId: 183,
          frontendSubCategoryName: "PHOTO_STUDIO",
          subCategoryFriendlyName: t("subcategories.PHOTO_STUDIO"),
          subCategoryKeywords: [],
        },
        {
          subId: 184,
          frontendSubCategoryName: "TELECOM",
          subCategoryFriendlyName: t("subcategories.TELECOM"),
          subCategoryKeywords: [],
        },
        {
          subId: 185,
          frontendSubCategoryName: "TAILOR_SHOP",
          subCategoryFriendlyName: t("subcategories.TAILOR_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 186,
          frontendSubCategoryName: "CLEANING_SERVICE",
          subCategoryFriendlyName: t("subcategories.CLEANING_SERVICE"),
          subCategoryKeywords: [],
        },
        {
          subId: 187,
          frontendSubCategoryName: "SHOE_REPAIR",
          subCategoryFriendlyName: t("subcategories.SHOE_REPAIR"),
          subCategoryKeywords: [],
        },
        {
          subId: 188,
          frontendSubCategoryName: "KEY_COPY_SERVICE",
          subCategoryFriendlyName: t("subcategories.KEY_COPY_SERVICE"),
          subCategoryKeywords: [],
        },
        {
          subId: 189,
          frontendSubCategoryName: "LAUNDRY_SERVICE",
          subCategoryFriendlyName: t("subcategories.LAUNDRY_SERVICE"),
          subCategoryKeywords: [],
        },
        {
          subId: 190,
          frontendSubCategoryName: "LOCKSMITH",
          subCategoryFriendlyName: t("subcategories.LOCKSMITH"),
          subCategoryKeywords: [],
        },
        {
          subId: 191,
          frontendSubCategoryName: "MOBILE_REPAIR",
          subCategoryFriendlyName: t("subcategories.MOBILE_REPAIR"),
          subCategoryKeywords: [],
        },
        {
          subId: 192,
          frontendSubCategoryName: "COMPUTER_REPAIR",
          subCategoryFriendlyName: t("subcategories.COMPUTER_REPAIR"),
          subCategoryKeywords: [],
        },
        {
          subId: 193,
          frontendSubCategoryName: "PACKAGING_SERVICE",
          subCategoryFriendlyName: t("subcategories.PACKAGING_SERVICE"),
          subCategoryKeywords: [],
        },
        {
          subId: 194,
          frontendSubCategoryName: "PRINT_SHOP",
          subCategoryFriendlyName: t("subcategories.PRINT_SHOP"),
          subCategoryKeywords: [],
        },
        {
          subId: 195,
          frontendSubCategoryName: "EVENT_PLANNING",
          subCategoryFriendlyName: t("subcategories.EVENT_PLANNING"),
          subCategoryKeywords: [],
        },
        {
          subId: 196,
          frontendSubCategoryName: "OTHER_SERVICES",
          subCategoryFriendlyName: t("subcategories.OTHER_SERVICES"),
          subCategoryKeywords: [],
        },
      ],
    },
    {
      id: 14,
      backendMainCategory: "OTHER",
      mainCategoryFriendlyName: t("categories.other"),
      mainCategoryImage: require("../../assets/images/category-images/other.png"),
      subCategories: [
        {
          subId: 197,
          frontendSubCategoryName: "OTHER",
          subCategoryFriendlyName: t("subcategories.OTHER"),
          subCategoryKeywords: [],
        },
      ],
    },
  ];

  const categoryMap = new Map<string, string>();
  const subCategoryMap = new Map<string, string>();

  categoriesArray.forEach((category) => {
    categoryMap.set(category.backendMainCategory, category.mainCategoryFriendlyName);
    category.subCategories.forEach((subCategory) => {
      subCategoryMap.set(subCategory.frontendSubCategoryName, subCategory.subCategoryFriendlyName);
    });
  });

  return { categories: categoriesArray, categoryMap, subCategoryMap };
};

export default useCategories;
