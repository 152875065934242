import { useTranslation } from "react-i18next";
import useCurrentDay from "../../hooks/useCurrentDay";
import useCurrentTime from "../../hooks/useCurrentTime";
import { IOpeningTimes } from "../../types/IOpeningTimes";
import "./StoreOpenTimes.css";

interface IStoreOpenTimesProps {
  openTimes: IOpeningTimes;
}

export const StoreOpenTimes: React.FC<IStoreOpenTimesProps> = ({ openTimes }) => {
  const { t } = useTranslation();
  const currentDay = useCurrentDay();
  const currentlyOpen = useCurrentTime(openTimes);

  const renderOpeningTimes = () => {
    return Object.entries(openTimes).map(([day, times]) => {
      const isToday = day === currentDay;
      const isOpenNow = isToday && currentlyOpen;
      const is24HoursOpen =
        times.isOpen && times.openingTime === "00:00" && times.closingTime === "23:59";

      return (
        <div key={day} className={`open-times-row ${isToday ? "highlight-today" : ""}`}>
          {/* Tag des Wochentags */}
          <span className={`day-cell ${isToday ? "bold" : ""}`}>
            {t(`days.${day}`)}
          </span>

          {/* Öffnungszeiten */}
          <span className={`times-cell ${isOpenNow ? "open-now" : "closed"}`}>
            {is24HoursOpen
              ? t("open_24_hours")
              : times.isOpen
              ? `${times.openingTime}-${times.closingTime}`
              : t("closed")}
          </span>
        </div>
      );
    });
  };

  return (
    <div className="store-open-times">
      <h3>{t("openingTimes")}</h3>
      <div className="open-times-table">{renderOpeningTimes()}</div>
    </div>
  );
};

export default StoreOpenTimes;
