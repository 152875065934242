import React from "react";
import googlePlayBadge from "../../assets/images/badges/app-store-badge.png";
import appStoreBadge from "../../assets/images/google-store.svg";
import {
  BASAR_PARTNER_ANDROID_APP_URL,
  BASAR_PARTNER_APP_DYNAMIC,
  BASAR_PARTNER_IOS_APP_URL,
  BASAR_USER_ANDROID_APP_URL,
  BASAR_USER_APP_DYNAMIC,
  BASAR_USER_IOS_APP_URL,
} from "../../components/links/AppStoreLinks";
import useTypedTranslation from "../../i18n/useTypedTranslation";
import "./AppStoreButtons.css";
import AppleBadgeBlack from "../../assets/images/badges/AppleBadgeBlack";
import GoogleBadgeBlack from "../../assets/images/badges/GoogleBadgeBlack";

interface AppStoreButtonsProps {
  type: "user" | "partner"; // Bestimmt, ob es für User oder Partner ist
  useDynamicLink?: boolean; // Falls dynamische Links genutzt werden sollen
}

const AppStoreButtons: React.FC<AppStoreButtonsProps> = ({
  type,
  useDynamicLink = false,
}) => {
  const links = {
    user: {
      ios: BASAR_USER_IOS_APP_URL,
      android: BASAR_USER_ANDROID_APP_URL,
      dynamic: BASAR_USER_APP_DYNAMIC,
    },
    partner: {
      ios: BASAR_PARTNER_IOS_APP_URL,
      android: BASAR_PARTNER_ANDROID_APP_URL,
      dynamic: BASAR_PARTNER_APP_DYNAMIC,
    },
  };

  const androidUrl = useDynamicLink ? links[type].dynamic : links[type].android;
  const iosUrl = useDynamicLink ? links[type].dynamic : links[type].ios;
  const { t } = useTypedTranslation();

  return (
    <div className="app-store-buttons">
      <a href={androidUrl} target="_blank" rel="noopener noreferrer">
        <GoogleBadgeBlack />
      </a>
      <a href={iosUrl} target="_blank" rel="noopener noreferrer">
        <AppleBadgeBlack />
      </a>
    </div>
  );
};

export default AppStoreButtons;
