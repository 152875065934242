import React from "react";
import ThreeStepComponent from "./ThreeStepComponent";
import step1Image from "../assets/images/steps/step1.png";
import step2Image from "../assets/images/steps/step2.png";
import step3Image from "../assets/images/steps/step3.png";
import { ReactComponent as STEP1ICON } from "../assets/images/step1icon.svg";
import { ReactComponent as STEP2ICON } from "../assets/images/step2icon.svg";
import { ReactComponent as STEP3ICON } from "../assets/images/step3icon.svg";
import useTypedTranslation from "../i18n/useTypedTranslation";

const BasarApp: React.FC = () => {
  const { t } = useTypedTranslation();
  const steps = [
    {
      icon: <STEP1ICON className="step-icon" />,
      image: step1Image,
      title: t("discover"),
      subtitle: "Alles da. Alles nah.",
      description:
        "Entdecke die Welt des lokalen Handels, ob Restaurants, Supermärkte, Friseur, Kosmetikstudios, Werkstätte und vieles mehr!",
    },
    {
      icon: <STEP2ICON className="step-icon" />,
      image: step2Image,
      title: "Geschäft auswählen",
      subtitle: "Coupon einlösen.",
      description:
        "Lokales zu unterstützen & dabei zu sparen geht nicht? Doch! Unsere Partner im lokalen Handel laden dich ein über Direktrabatte & Geschenke für Produkte oder Dienstleistungen.",
    },
    {
      icon: <STEP3ICON className="step-icon" />,
      image: step3Image,
      title: "Choose a restaurant",
      subtitle: "Sofort sparen.",
      description:
        "Schnell und unkompliziert für alle. Alle Deals griffbereit in deiner Hosentasche. Deine Buchung zeigst du ganz einfach dem Mitarbeiter im Geschäft.",
    },
  ];

  return (
    <div className="basar-game-container">
      <ThreeStepComponent steps={steps} title={"BASAR App"} />
    </div>
  );
};

export default BasarApp;
