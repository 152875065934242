import React from "react";
import { useNavigate } from "react-router-dom";
import "./StoreDealCard.css";
import NormalTextComponent from "../styles/NormalTextComponent";
import { IDeals } from "../types/IDeals";
import useCategories from "./CATEGORIES/useCategories";

interface StoreDealCardProps {
  store: IDeals;
  className?: string;
}

const StoreDealCard: React.FC<StoreDealCardProps> = ({
  store,
  className = "",
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (store.storeId === -1) return; // Dummy-Karte ist nicht klickbar
    const storeURL = generateStoreURL(
      store.storeAddress.city,
      store.storeName,
      store.storeId
    );
    navigate(storeURL);
  };

  const generateStoreURL = (
    city: string,
    storeName: string,
    storeId: number
  ) => {
    return `/stores/${city.toLowerCase()}/${storeName
      .toLowerCase()
      .replace(/\s+/g, "-")}-${storeId}`;
  };
  const storeAddress = `${store?.storeAddress?.street} ${
    store?.storeAddress?.number && ","
  } ${store?.storeAddress?.zipCode} ${store?.storeAddress?.city}`.trim();
  const { categoryMap, subCategoryMap } = useCategories();

  return (
    <div className={`store-card ${className}`} onClick={handleClick}>
      <img
        src={store.shopImgUrl}
        alt={store.storeName}
        className="store-image"
      />
      <div className="coupon-header-text">{store.coupon.benefitHeader}</div>
      <div className="store-info-box">
        <NormalTextComponent className="coupon-body-text">
          {store.coupon.header}
        </NormalTextComponent>
        <NormalTextComponent className="coupon-store-address">
          {storeAddress}
        </NormalTextComponent>
        <NormalTextComponent className="coupon-store-name">
          {store.storeName}
        </NormalTextComponent>
        <NormalTextComponent className="coupon-store-category">
          {categoryMap.get(store.parentCategory) || store.parentCategory}
        </NormalTextComponent>
        <NormalTextComponent className="store-subcategories">
          {store.subCategories
            .map(
              (subCategory) => subCategoryMap.get(subCategory) || subCategory
            )
            .join(", ")}{" "}
        </NormalTextComponent>
      </div>
    </div>
  );
};

export default StoreDealCard;
